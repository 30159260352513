import React from "react";
import { GradingProvider } from "../../grades/contexts/GradingContext";
import GradingDetails from "../../grades/GradingDetails";

const Performance = ({ onContinue }) => {

    return (
        <div className="flex flex-col items-center justify-center space-y-4 pt-10">
            <h1 class=" text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white tracking-tight">Great job!</h1>
            <p class="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400 text-center">You can now view your performance.<br />You also have the ability to read back your interaction with the patient in the Transcript section.</p>
            <GradingDetails displayOpenQuestions={false} />
            <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                <button onClick={onContinue} className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                    Debriefing
                    <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div>
  </div>
 

        
    );
}

export default Performance;