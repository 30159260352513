import React, { useEffect } from "react";
import { useAssessment } from "../contexts/AssessmentContext";
import VR from "../../../assets/images/2.png"

const Start = ({ onReturn, onContinue }) => {
    const { assessment } = useAssessment();

    return (
        <section class="bg-white dark:bg-gray-900">
            <div class="px-4 py-8 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white tracking-tight">Time to enter the simulation.</h1>
                <p class="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">You are now ready to enter the simulation.<br />Once entered, you will find your assignment in the simulation under the name: {assessment ? assessment.assignmentName : "Loading..."}.<br />When you are finished you can press the Continue button to finalize the assignment and view your performance.</p>
                <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                    <button onClick={onReturn} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        <svg class="mr-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L6.414 9H17a1 1 0 110 2H6.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
                        Prebriefing
                    </button>
                    <button onClick={onContinue} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                        View Performance
                    </button>
                </div>
                <div class="flex justify-center items-center lg:my-16 divide-x divide-gray-200 dark:divide-gray-700">
                    <img class="w-50 h-48 rounded-full border-2 border-white dark:border-gray-800" src={VR} alt="VR" />
                </div>
                <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white tracking-tight">Need help?</h1>
                <p class="mb-8 font-light text-gray-500 md:text-lg lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">If you need help on setting up, you can watch the demonstration video below to help guide you in the simulation.</p>
                <iframe class="mx-auto w-full max-w-2xl h-64 rounded-lg sm:h-96" src="https://www.youtube.com/embed/mIq5ld6Nusg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </section>
    );
}

export default Start;