import React, { useEffect, useState } from 'react';
import { useScenario } from '../contexts/ScenarioContext';
import { getFileUrl } from '../../../services/FirebaseService';

const ScenarioPicture = () => {
  const { scenario, setFile, getFile } = useScenario();
  const [url, setUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      if (scenario && scenario.scenarioPicture) {
        const u = await getFileUrl(scenario.scenarioPicture);
        if (u) {
          setUrl(u);
        } else {
          const file = getFile('scenarioPicture');
          if (file) {
            const localUrl = URL.createObjectURL(file);
            setUrl(localUrl);
          }
        }
      }
    };
    fetchUrl();
  }, [scenario?.scenarioPicture]);

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Scenario Picture</h2>
      <div className="flex items-center">
        <label className="block">
          <span className="sr-only">Choose scenario picture file</span>
          <input
            id="pictureInput"
            type="file"
            accept="image/*"
            onChange={(e) => { setFile(e.target.files[0], 'scenarioPicture') }}
            className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100
                    dark:file:bg-gray-600 dark:file:text-gray-200
                    cursor-pointer"
            style={{ display: 'none' }}
          />
        </label>
        <button
          onClick={() => document.getElementById('pictureInput').click()}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {scenario?.scenarioPicture ? 'Edit Picture' : 'Choose Picture'}
        </button>
        {url && (
          <img src={url} alt="Scenario" className="ml-3 h-20 w-20 object-cover rounded-lg" />
        )}
      </div>
    </div>
  );
};

export default ScenarioPicture;
