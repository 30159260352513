/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { collection, getDoc, getDocs, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { usePopUp } from '../../contexts/PopUpContext';
import CreateScenario from '../creators/CreateScenario';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Datepicker } from 'flowbite-react';
import { HiHome } from "react-icons/hi";
import { useAppData } from '../../contexts/AppDataContext';
import SkeletonLoader from '../../components/SkeletonLoader';

export default function ScenariosOverview() {
  const { scenarios, isLoading } = useAppData();
  const { showPopUp } = usePopUp();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(scenarios);
  }, [scenarios]);

  const handleAddScenarioClick = () => {
    showPopUp(<CreateScenario onClose={() => showPopUp(null)} />);
  }

  const handleScenarioClick = (scenarioId) => {
    navigate(`/main/scenarioDetails/${scenarioId}`);
  };

  return (
    <div>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href='#' onClick={(e) => {
                e.preventDefault();
                navigate("/main/dashboard")
              }}>
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Scenarios</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              COURSETA Scenarios
            </h1>
          </div>
          <div className="block items-center sm:flex mt-8 mb-4">
            <div className="flex w-full items-center">
              <button type="button" onClick={handleAddScenarioClick}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-2 -ml-1" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                Add new Scenario
              </button>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {isLoading ? (
        <SkeletonLoader type='card' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
          {scenarios?.map((s) => (
            <div key={s.id} className="col-span-1 sm:col-span-1 md:col-span-1 xl:col-span-1 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-lg shadow overflow-hidden">
              <div className="p-5">
                <a href="#">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{s.name}</h5>
                </a>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">QuestionnaireId: {s.chosenQuestionnaireId}</p>
                <a onClick={() => handleScenarioClick(s.id)} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  See more
                  <svg className="rtl:rotate-180 w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
