import { createContext, useContext, useEffect, useState } from "react";
import {
  fetchDataObject,
  updateDocument,
  addDocument,
} from "../../../services/FirebaseService";
import { useNotification } from "../../../contexts/NotificationContext";
import { useEdgesState, useNodesState } from "reactflow";
import { useParams } from "react-router-dom";
import { medicationsCollection, scenariosCollection, scriptDataCollection, conditionsCollection, gradesScriptingCollection } from "../../../firebase";
import { useScenario } from "../../scenarios/contexts/ScenarioContext";

const ScriptingContext = createContext();

export function useScripting() {
  return useContext(ScriptingContext);
}

export function ScriptingProvider({ children }) {
  // const [scriptData, setScriptData] = useState({});
  const { medicationId, conditionId, gradesscriptId } = useParams();
  const { showNotification } = useNotification();
  const [scriptId, setScriptId] = useState(null);

  const { scenario } = useScenario();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (scenario != null) {
        let id = scenario.scriptId;
        if (!id) {
          id = await addDocument({ nodes: [], edges: [] }, scriptDataCollection);
          console.log(scenario);
          await updateDocument(scenario.id, scenariosCollection, { scriptId: id });
        }
        setScriptId(id);
        const data = await fetchDataObject(id, scriptDataCollection);
        if (data == null) {
          await addDocument({ nodes: [], edges: [] }, scriptDataCollection, id);
        }
        console.log(data);
        setNodes(data?.nodes);
        setEdges(data?.edges);
      }
      else if (medicationId != null) {
        const data = await fetchDataObject(medicationId, medicationsCollection);
        console.log(data);
        setNodes(data?.nodes ?? []);
        setEdges(data?.edges ?? []);
      }
      else if (conditionId != null) {
        const data = await fetchDataObject(conditionId, conditionsCollection);
        console.log(data);
        setNodes(data?.nodes ?? []);
        setEdges(data?.edges ?? []);
      }
      else if (gradesscriptId != null) { // Handle Grade Scripting
        const data = await fetchDataObject(gradesscriptId, gradesScriptingCollection);
        console.log(data);
        setNodes(data?.nodes ?? []);
        setEdges(data?.edges ?? []);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("New script data:", { nodes, edges });
  }, [nodes, edges]);

  const generateId = (prefix) => `${prefix}-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  const addNode = (type, position) => {
    // setScriptData((prev) => { return { ...prev, nodes: (prev.nodes ?? []).concat({ id: generateId("node"), type, position }) } });
    const newNode = {
      id: generateId("node"),
      type,
      position,
    };
    setNodes((prev) => (prev ?? []).concat(newNode));
  }

  const updateNodeData = (id, newData) => {
    // const _ = require('lodash');
    setNodes((prev) => {
      return prev.map(n => {
        if (n.id === id) {
          return { ...n, data: { ...n.data, ...newData } }
        }
        return n;
      })
    });
  }

  const removeNodeData = (id, field) => {
    setNodes((prev) => {
      return prev.map(n => {
        if (n.id === id) {
          const { [field]: _, ...newData } = n.data;
          return { ...n, data: newData }
        }
        return n;
      })
    });
  }

  const addEdge = (params) => {
    setEdges((prev) => {
      //Updated to ensure no duplicates are allowed
      const newEdge = {
        id: generateId("edge"),
        source: params.source,
        target: params.target,
        sourceHandle: params.sourceHandle,
        targetHandle: params.targetHandle,
      };

      const edgeExists = prev?.some(
        (edge) =>
          edge.source === newEdge.source &&
          edge.target === newEdge.target &&
          edge.sourceHandle === newEdge.sourceHandle &&
          edge.targetHandle === newEdge.targetHandle
      );

      if (edgeExists) {
        return prev;
      } else {
        return (prev ?? []).concat(newEdge);
      }
    })
    //setEdges((prev) => (prev ?? []).concat({ id: generateId("edge"), source: params.source, target: params.target, sourceHandle: params.sourceHandle, targetHandle: params.targetHandle }));
  };

  const saveScript = async () => {
    console.log("Saving script data: ", { nodes, edges });
    if (medicationId != null) {
      await updateDocument(medicationId, medicationsCollection, { nodes: nodes, edges: edges });
    }
    else if (scriptId != null) {
      await updateDocument(scriptId, scriptDataCollection, { nodes: nodes, edges: edges });
    }
    else if (conditionId != null) {
      await updateDocument(conditionId, conditionsCollection, { nodes: nodes, edges: edges });
    }
    else if (gradesscriptId != null) { // Handle Grade Scripting
      await updateDocument(gradesscriptId, gradesScriptingCollection, { nodes: nodes, edges: edges });
    }
    // await saveScriptData(scriptId, {
    //   nodes: nodes,
    //   edges: edges,
    // });
    // await saveScriptIdToScenario(scenarioId, scriptId);
    showNotification("success", "Scripting saved!");

  };

  return (
    <ScriptingContext.Provider
      value={{
        nodes,
        addNode,
        updateNodeData,
        removeNodeData,
        edges,
        addEdge,
        onNodesChange,
        onEdgesChange,
        scriptId,
        // scriptData,
        saveScript,
      }}
    >
      {children}
    </ScriptingContext.Provider>
  );
}