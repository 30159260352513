import React from 'react'
import { useNavigate } from 'react-router-dom';
import { startAssignmentForUser } from '../../../services/FirebaseService';
import { useUser } from '../../../contexts/UserContext';

export default function StartSimPopUp({ onClose, navigate, assessment }) {
    const user = useUser();

    const handleConfirm = () => {
        navigate(`/main/simulationDetails/${assessment.id}`);
        startAssignmentForUser(user.id, assessment.id);
        onClose();
    };

    const handleCancel = () => {
        // Annuleer de actie
        onClose(); // Sluit de pop-up
    };
    return (
        <>
            {/* <!-- Main modal --> */}
            <div id="deleteAlertModal" tabindex="-1" aria-hidden="true" class="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center items-center w-full md:inset-0 h-modal md:h-full">
                <div class="relative p-4 w-full max-w-md h-full md:h-auto">
                    {/* <!-- Modal content --> */}
                    <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteAlertModal">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                        <h3 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">You are about to start an assessment</h3>
                        <p class="mb-4 font-light text-gray-500 dark:text-gray-400">Are you sure you want to start? Your grade will be registered once you continue</p>
                        <div class="p-4 mb-4 text-sm text-left text-orange-700 bg-orange-100 rounded-lg sm:mb-5 dark:bg-gray-700 dark:text-orange-300" role="alert">
                            <div class="items-center mb-1">
                                <svg class="inline flex-shrink-0 mr-1 w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Danger</span>
                                <span class="font-semibold leading-none">Warning</span>
                            </div>
                            <p>By continuing you accept that your grade will be registered and that you cant try again</p>
                        </div>
                        <div class="flex justify-center items-center space-x-4">
                            <button onClick={handleCancel} data-modal-toggle="deleteAlertModal" type="button" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                No, cancel
                            </button>
                            <button onClick={handleConfirm} type="submit" class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Yes, continue
                                <svg class="rtl:rotate-180 w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
