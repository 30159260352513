import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import { ReactComponent as GradesIcon } from "../assets/icons/grades.svg";
import { ReactComponent as LibraryIcon } from "../assets/icons/library.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { ReactComponent as CoursesIcon } from "../assets/icons/courses.svg";


import {
    HiUserGroup,
    HiDocumentReport,
    HiChartPie,
    HiCollection,
    HiViewGrid,
    HiTicket,
    HiClipboardList,
    HiBeaker
} from "react-icons/hi";
import { Medication } from "@mui/icons-material";

export default function Layout() {
    const sidebar = [
        { label: 'Dashboard', path: '/main/dashboard', icon: HiChartPie },
        { label: 'Assignments', path: '/main/assesmentsoverview', roles: ['Student', 'Teacher', 'Admin'], icon: CalendarIcon },
        { label: 'Library', path: '/main/libraryoverview', roles: ['Teacher', 'Admin'], icon: LibraryIcon },
        { label: 'Courses', path: '/main/classroomoverview', roles: ['Teacher', 'Admin', 'Student'], icon: CoursesIcon },
        { label: 'Schools', path: '/main/schoolsoverview', roles: ['Admin'], icon: HiBeaker },
        { label: 'Grades', path: '/main/gradesoverview', icon: GradesIcon },
        { label: 'Scenarios', path: '/main/scenariosoverview', roles: ['Admin'], icon: HiCollection },
        { label: 'Medications', path: '/main/medicationsoverview', roles: ['Admin'], icon: Medication },
        { label: 'Conditions', path: '/main/conditionsoverview', roles: ['Admin'], icon: Medication },
        { label: 'GradesScripting', path: '/main/gradesscriptoverview', roles: ['Admin'], icon: Medication },
        { label: 'Tokens', path: '/main/tokensoverview', roles: ['Admin'], icon: HiTicket },
        { label: 'Questionaires', path: '/main/questionnairesoverview', roles: ['Admin'], icon: HiClipboardList },
        { label: 'Users', path: '/main/users', roles: ['Admin', 'Teacher'], icon: UsersIcon },
    ];

    return (
        <div className="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen flex flex-col">
            <Header />
            <div className="flex flex-grow">
                <Sidebar items={sidebar} />
                <main className="flex-grow p-4 ml-64">
                    <Outlet />
                </main>
            </div>
        </div>
    );
}
