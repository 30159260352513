import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { usePopUp } from '../../contexts/PopUpContext';
import { useNotification } from '../../contexts/NotificationContext';
import { addDocument } from '../../services/FirebaseService';
import { emailTemplate } from '../../emails/Help_Email';
import { useUser } from '../../contexts/UserContext';


export default function HelpPopUp({ onClose }) {
  const { showNotification } = useNotification();
  const { isPopUpVisible } = usePopUp();
  const [selectedHelp, setSelectedHelp] = useState(null);
  const [emailContent, setEmailContent] = useState('');
  const user = useUser();

  const handleSendEmail = async (event) => {
    event.preventDefault();
    // const safeContent = setEmailContent(emailContent);
    const htmlcontent = emailTemplate(user.firstName, emailContent);


    const emailData = {
      to: [
        {
          email: user.email,
          name: user.firstName
        },
        {
          email: 'milan@algointeractive.com',
          name: 'Support 1'
        },
        {
          email: 'Info@tactilevr.com',
          name: 'Support 2'
        }
      ],
      from: {
        email: 'info@coursetavr.com',
        name: 'Courseta Helpdesk'
      },
      subject: selectedHelp, // Gebruik de geselecteerde hulp categorie als onderwerp
      html: htmlcontent, // Gebruik de inhoud van de email als HTML
      // Voeg eventuele andere vereiste velden toe
    };

    try {
      // Gebruik de addDocument functie om het e-mail document toe te voegen
      const documentId = await addDocument(emailData, 'emails'); // Veronderstelt dat 'emails' de collectie is die MailerSend monitort
      console.log('Email scheduled with document ID:', documentId);
      showNotification('success', 'Email sent successfully!');
      onClose(); // Sluit het dialoogvenster na succes
    } catch (error) {
      console.error('Error sending email:', error);
      showNotification('danger', 'Failed to send email.');
    }
  };


  const handleHelpChoice = (choice) => {
    setSelectedHelp(choice);
  };

  const handleBackClick = () => {
    setSelectedHelp(null);
  };

  const handleEmailContentChange = (event) => {
    setEmailContent(event.target.value);
  };

  return (
    <Transition appear show={isPopUpVisible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          {/* This part handles the centering of the modal contents */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dark:bg-gray-800">
              {selectedHelp ? (
                // Form UI after a help category is selected
                <div>
                  <button onClick={handleBackClick} className="text-blue-600 hover:text-blue-800 dark:hover:text-blue-400">
                    {/* Back arrow icon (consider using an actual icon) */}
                    ← Back
                  </button>
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    {selectedHelp}
                  </h3>
                  <form className="space-y-6 mt-4" onSubmit={handleSendEmail}>
                    <textarea
                      className="block w-full p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      rows="4"
                      placeholder="Type your question here..."
                      value={emailContent}
                      onChange={handleEmailContentChange} // Zorg dat je de state bijwerkt als de tekst verandert
                    ></textarea>
                    <div className="flex justify-between items-center">
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                // Initial help options UI
                <div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    How can we help you?
                  </Dialog.Title>
                  <div className="mt-2">
                    {['General questions', 'Technical support', 'Billing and payments', 'Feedback and suggestions'].map((item, index) => (
                      <div
                        key={index}
                        role="button"
                        tabIndex={0}
                        className="first:mt-0 mt-2 p-2 border rounded-md cursor-pointer text-sm text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onClick={() => handleHelpChoice(item)}
                      //onKeyPress={() => handleHelpChoice(item)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
