import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Calendar styling
import AssessmentCard from './components/AssesmentCard';
import { useUser } from '../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import { HiHome, HiOutlineTrash, HiOutlineBookOpen } from 'react-icons/hi';
import { deleteAssignmentFirebase } from '../../services/FirebaseService';
import { useOverview } from '../../contexts/OverviewContext';
import Pagination from '../../components/Pagination';
import Accordion from '../../components/Accordion';
import { Table } from 'flowbite-react';

export default function AssessmentsOverview() {
  const { assignments, grades, courses } = useAppData(); // Fetch courses as well
  const user = useUser();
  const navigate = useNavigate();

  const [incompleteAssignments, setIncompleteAssignments] = useState([]);
  const [dueAssignments, setDueAssignments] = useState([]);
  const [overdueAssignments, setOverdueAssignments] = useState([]);
  const [activeTab, setActiveTab] = useState('total'); // State for handling tabs

  // Calendar localizer
  const localizer = momentLocalizer(moment);

  // Colors for due and overdue assignments
  const overdueColor = "text-red-500";
  const dueColor = "text-green-500";

  // Debug active tab
  console.log('Active Tab:', activeTab);  // Check if 'calendar' is logged when clicking Calendar tab

  // Use OverviewContext for search and pagination (admin only)
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage
  } = useOverview();

  // Admin logic for search, sorting, pagination
  useEffect(() => {
    if (user.roleName === 'Admin') {
      setSearchQuery(''); // Clear search on component load for Admin
      setSortConfig({ key: 'assignmentName', direction: 'ascending' });
      return () => {
        setSearchQuery(''); // Reset on unmount
        setSortConfig({ key: 'assignmentName', direction: 'ascending' });
      };
    }
  }, [setSearchQuery, setSortConfig, setCurrentPage, user.roleName]);

  useEffect(() => {
    if (grades) {
      const userGrades = grades[user.id];
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Use only date, not time

      console.log('All Assignments Before Classification:', assignments);

      const classifiedAssignments = assignments.reduce((acc, assignment) => {
        const assignmentDueDate = assignment.dueDate?.toDate ? assignment.dueDate.toDate() : new Date(assignment.dueDate);

        // Skip if dueDate is invalid
        if (isNaN(assignmentDueDate.getTime())) {
          console.log(`Invalid due date for assignment: ${assignment.assignmentName}`, assignmentDueDate);
          return acc;
        }

        const dueDateWithoutTime = new Date(assignmentDueDate.getFullYear(), assignmentDueDate.getMonth(), assignmentDueDate.getDate());
        const isCompleted = userGrades?.some(grade => grade.assessmentID === assignment.id);

        // If not completed, classify the assignment
        if (!isCompleted) {
          // If the assignment is overdue
          if (dueDateWithoutTime < today) {
            acc.overdue.push(assignment);
          } else {
            // Else it's a due (upcoming) assignment
            acc.due.push(assignment);
          }
        }

        return acc;
      }, { due: [], overdue: [] });

      // Set the state for incomplete, due, and overdue assignments
      setIncompleteAssignments(classifiedAssignments.due);
      setDueAssignments(classifiedAssignments.due);
      setOverdueAssignments(classifiedAssignments.overdue);

      console.log('Due Assignments:', classifiedAssignments.due);
      console.log('Overdue Assignments:', classifiedAssignments.overdue);
    }
  }, [assignments, grades, user.id]);

  // Admin-specific logic: filter assignments based on search query
  const filteredAssignments = user.roleName === 'Admin' && searchQuery
    ? assignments.filter(assignment =>
        assignment.assignmentName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : assignments;

  const handleDeleteAssignment = async (courseId, assignmentId) => {
    const confirmation = window.confirm("Are you sure you want to delete this assignment?");
    if (confirmation) {
      try {
        await deleteAssignmentFirebase(courseId, assignmentId);
        // Update state to reflect the deletion
        setIncompleteAssignments(prev => prev.filter(assignment => assignment.id !== assignmentId));
      } catch (error) {
        console.error('Error deleting assignment:', error);
      }
    }
  };

  // Helper function to find className by classroomId
  const getClassNameById = (classroomId) => {
    const course = courses.find(course => course.id === classroomId); // Find the matching course
    return course ? course.className : "Unknown Class"; // Return className or "Unknown" if not found
  };

  // Pagination logic for Admin only
  const totalPages = Math.ceil(filteredAssignments.length / itemsPerPage);
  const indexOfLastAssignment = currentPage * itemsPerPage;
  const indexOfFirstAssignment = indexOfLastAssignment - itemsPerPage;
  const currentAssignments = filteredAssignments.slice(indexOfFirstAssignment, indexOfLastAssignment);

  // Combine both due and overdue assignments into one array
  const allAssignments = [...dueAssignments, ...overdueAssignments];

  const calendarEvents = allAssignments.map(assignment => {
    const startDate = assignment.dueDate && typeof assignment.dueDate.toDate === 'function'
      ? assignment.dueDate.toDate()
      : new Date(assignment.dueDate || new Date());

    const classroomName = getClassNameById(assignment.classroomId);

    return {
      title: `${assignment.assignmentName} - ${classroomName}`,  // Display both assignment name and classroom name
      start: startDate,
      end: startDate,
      allDay: true,
      resource: assignment
    };
  });

  console.log('Calendar Events:', calendarEvents);

  const calendarLegend = (
    <div className="flex justify-between mb-6">
      <div className="flex items-center space-x-2">
        <span className="w-4 h-4 bg-blue-500 inline-block rounded"></span>
        <span className="text-gray-600 dark:text-gray-300">Assignment</span>
      </div>
    </div>
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center mb-6">
          <HiOutlineBookOpen className="text-4xl text-gray-600 dark:text-white mr-3" />
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Assignment
          </h1>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex mb-4 space-x-8 border-b-2 border-gray-200">
        <button
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'total' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
          onClick={() => setActiveTab('total')}
        >
          Total Assignment
        </button>
        <button
          className={`px-4 pb-2 text-lg font-medium ${activeTab === 'calendar' ? 'text-blue-500 border-b-4 border-blue-500' : 'text-gray-600'} dark:text-white`}
          onClick={() => setActiveTab('calendar')}
        >
          Calendar
        </button>
      </div>

      {/* Conditionally render Total Assignment or Calendar view */}
      {activeTab === 'total' ? (
        <>
          {user.roleName === 'Admin' ? (
            <>
              <div className="flex justify-between items-center mb-4">
                <form className="flex items-center w-full" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="search"
                    id="search-assignments"
                    className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Search assignments"
                    onChange={handleSearchChange}
                  />
                </form>
              </div>

              <div className="container mx-auto py-10">
                <Table hoverable={true}>
                  <Table.Head>
                    <Table.HeadCell>Assignment Name</Table.HeadCell>
                    <Table.HeadCell>Class Name</Table.HeadCell>
                    <Table.HeadCell>Actions</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {currentAssignments.map((assignment) => (
                      <Table.Row key={assignment.id}>
                        <Table.Cell>{assignment.assignmentName}</Table.Cell>
                        <Table.Cell>{getClassNameById(assignment.classroomId)}</Table.Cell>
                        <Table.Cell>
                          <HiOutlineTrash
                            className="text-red-500 cursor-pointer"
                            onClick={() => handleDeleteAssignment(assignment.classroomId, assignment.id)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <div className="mt-4 text-right text-gray-600 dark:text-gray-300">
                  <span>Total assignments: {filteredAssignments.length}</span>
                </div>

                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  numItemsPerPage={itemsPerPage}
                  onNumItemsPerPageChange={handleNumItemsPerPageChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="space-y-6">
                <Accordion
                  title={
                    <>
                      Overdue Assignment (
                      <span className={overdueColor}>
                        {overdueAssignments.length < 10 ? '0' + overdueAssignments.length : overdueAssignments.length}
                      </span>)
                    </>
                  }
                  content={
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {overdueAssignments.map((assignment) => (
                        <AssessmentCard key={assignment.id} assessment={assignment} />
                      ))}
                    </div>
                  }
                  isOpenByDefault={true}
                  itemCount={overdueAssignments.length}
                  showViewAll={false}
                />

                <Accordion
                  title={
                    <>
                      Due Assignment (
                      <span className={dueColor}>
                        {dueAssignments.length < 10 ? '0' + dueAssignments.length : dueAssignments.length}
                      </span>)
                    </>
                  }
                  content={
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {dueAssignments.map((assignment) => (
                        <AssessmentCard key={assignment.id} assessment={assignment} />
                      ))}
                    </div>
                  }
                  isOpenByDefault={true}
                  itemCount={dueAssignments.length}
                  showViewAll={false}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {calendarLegend}

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              eventPropGetter={(event) => ({
                style: { 
                  backgroundColor: '#1E90FF', 
                  color: 'white', 
                  borderRadius: '8px',
                  padding: '5px',
                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
                },
              })}
              views={['month', 'week', 'day']}
              defaultView="month"
            />
          </div>
        </>
      )}
    </div>
  );
}
