import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../../contexts/AppDataContext';
import SkeletonLoader from '../../components/SkeletonLoader';
import { HiHome } from 'react-icons/hi';
import LatestSubmissions from './components/LatestSubmissions';
import CourseAnalyticsGraph from './components/CourseAnalyticsGraph';
import UpcomingAssignments from './components/UpcomingAssignments';

export default function Dashboard() {
  const user = useUser();
  const navigate = useNavigate();
  const { isLoading } = useAppData();

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      let coursesQuery;

      if (user.roleName === 'Admin') {
        // Admin sees all courses
        coursesQuery = query(collection(firestore, 'classRooms'));
      } else if (user.roleName === 'Teacher') {
        // Teacher sees only their courses
        coursesQuery = query(
          collection(firestore, 'classRooms'),
          where('attendees', 'array-contains', user.id)
        );
      } else if (user.roleName === 'Student') {
        // Student sees only their attending classes
        const attendingClasses = user.attendingClasses || [];
        coursesQuery = query(
          collection(firestore, 'classRooms'),
          where('__name__', 'in', attendingClasses)
        );
      }

      const querySnapshot = await getDocs(coursesQuery);
      const coursesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCourses(coursesData);
    };

    fetchCourses().catch(console.error);
  }, [user]);

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="container mx-auto">
        {isLoading ? (
          <SkeletonLoader type="card" />
        ) : (
          <>
            {/* Dashboard Header */}
            <div className="flex items-center mb-6">
              <HiHome className="text-3xl text-blue-500" />
              <h1 className="ml-2 text-2xl font-bold text-gray-700">Dashboard</h1>
            </div>

            {user && user.roleName === 'Admin' && (
              <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 xl:grid-cols-3">
                {/* Admin specific components */}
                <div className="xl:col-span-3">
                <CourseAnalyticsGraph />
                  </div>
               
                <TotalUsers />
                <TotalPendingRegistrations />
                <TotalGrades />
                <TotalSchools />
                <TotalAnomUsers />
                <TotalCourses />
                <TotalAssessments />
                <TotalModules />
                <UsersByRole />
                <TotalScenarios />
                <LatestSubmissions limit={20}/>
                <UpcomingAssignments limit={20}/>
              </div>
            )}

            {user && (user.roleName === 'Teacher' || user.roleName === 'Student') && (
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
                <div className="xl:col-span-2">
                  <CourseAnalyticsGraph />
                  <br></br>
                  <UpcomingAssignments limit={10} />
                </div>
                <div>
                  
                  <LatestSubmissions  limit={10} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

// Helper component for counting total items in a collection
const TotalItems = ({ collectionPath, title }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchItems = async () => {
      const q = query(collection(firestore, collectionPath));
      const querySnapshot = await getDocs(q);
      setTotal(querySnapshot.size);
    };

    fetchItems().catch(console.error);
  }, [collectionPath]);

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="w-full">
        <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">{title}</h3>
        <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">{total}</span>
      </div>
    </div>
  );
};

// Component for showing total users in different roles
const UsersByRole = () => {
  const [rolesCount, setRolesCount] = useState({
    admin: 0,
    student: 0,
    teacher: 0,
    individual: 0
  });

  useEffect(() => {
    const fetchRoles = async () => {
      const usersQuery = query(collection(firestore, 'users'));
      const querySnapshot = await getDocs(usersQuery);
      const roles = {
        admin: 0,
        student: 0,
        teacher: 0,
      };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.roleName && roles.hasOwnProperty(data.roleName.toLowerCase())) {
          roles[data.roleName.toLowerCase()]++;
        }
      });

      setRolesCount(roles);
    };

    fetchRoles().catch(console.error);
  }, []);

  const totalUsers = rolesCount.admin + rolesCount.student + rolesCount.teacher;
  const calculatePercentage = (role) => totalUsers ? ((rolesCount[role] / totalUsers) * 100).toFixed(2) : 0;

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
      <div className="w-full">
        <h3 className="mb-2 text-base font-normal text-gray-500 dark:text-gray-400">Users by role</h3>
        {Object.keys(rolesCount).map((role) => (
          <div className="flex items-center justify-between mb-2" key={role}>
            <div className="flex-1">
              <div className="w-16 text-sm font-medium dark:text-white">{role.charAt(0).toUpperCase() + role.slice(1)}</div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-primary-600 h-2.5 rounded-full dark:bg-primary-500" style={{ width: `${calculatePercentage(role)}%` }}></div>
              </div>
            </div>
            <span className="ml-2 text-sm font-medium dark:text-white">{calculatePercentage(role)}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Various total statistics components for Admin dashboard
const TotalUsers = () => {
  return <TotalItems collectionPath="users" title="Total Users" />;
};

const TotalPendingRegistrations = () => {
  return <TotalItems collectionPath="pendingRegistrations" title="Total Pending" />;
};

const TotalSchools = () => {
  return <TotalItems collectionPath="schools" title="Total Schools" />;
};

const TotalGrades = () => {
  return <TotalItems collectionPath="grades" title="Total Grades Obtained" />;
};

const TotalAssessments = () => {
  return <TotalItems collectionPath="assessments" title="Total Assignments Assigned" />;
};

const TotalModules = () => {
  return <TotalItems collectionPath="modules" title="Total Modules In Use" />;
};

const TotalAnomUsers = () => {
  return <TotalItems collectionPath="anonymous_users" title="Total Anonymous Users" />;
};

const TotalCourses = () => {
  return <TotalItems collectionPath="classRooms" title="Total Courses" />;
};

const TotalScenarios = () => {
  return <TotalItems collectionPath="scenarios" title="Total Scenarios" />;
};

