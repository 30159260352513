/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import CreateClassroom from '../courses/CreateClassRooms';
import { usePopUp } from '../../contexts/PopUpContext';
import { useNavigate } from 'react-router';
import { useUser } from '../../contexts/UserContext';
import { Breadcrumb, Button, Dropdown, DropdownItem } from 'flowbite-react';
import {
  HiHome,
  HiOutlineEye,
  HiOutlineTrash,
  HiOutlineDotsVertical
} from "react-icons/hi";
import { useAppData } from '../../contexts/AppDataContext';
import { firestore } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNotification } from '../../contexts/NotificationContext';
import Pagination from '../../components/Pagination';
import { useOverview } from '../../contexts/OverviewContext';
import SkeletonLoader from '../../components/SkeletonLoader';
import Accordion from '../../components/Accordion';
import { HiX, HiOutlineAcademicCap } from 'react-icons/hi';
import { updateDoc, doc } from 'firebase/firestore';
import { FiImage } from 'react-icons/fi'; // Icon for image upload
import { MdPalette } from 'react-icons/md'; // Icon for color palette




export default function ClassRoomOverview() {
  const user = useUser();
  const { showPopUp } = usePopUp();
  const navigate = useNavigate();
  const { introductionCourse } = useAppData();

  return (
    <div>
      {user?.roleName === 'Admin' ? (
        <SchoolCoursesOverview user={user} />
      ) : (
        <CoursesTable user={user} />
      )}
    </div>
  );
}

function AddCourseModal() {
  const { showPopUp } = usePopUp();
  const { addCourse } = useAppData();

  const handleAddCourse = () => {
    showPopUp(<CreateClassroom onClose={() => showPopUp(null)} addCourse={addCourse} />);
  };

  return (
    <button onClick={handleAddCourse} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      Add course
      <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
      </svg>
    </button>
  );
}

const SchoolCoursesOverview = ({ user }) => {
  const [schools, setSchools] = useState([]);
  const [classRooms, setClassRooms] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage,
    sortConfig,
    handleSort
  } = useOverview();

  useEffect(() => {
    setSearchQuery('');
    setSortConfig({ key: 'name', direction: 'ascending' });

    return () => {
      setSearchQuery('');
      setSortConfig({ key: 'name', direction: 'ascending' });
    };
  }, [setSearchQuery, setSortConfig, setCurrentPage]);

  const { isLoading, deleteCourse } = useAppData();

  useEffect(() => {
    const fetchSchools = async () => {
      const querySnapshot = await getDocs(collection(firestore, "schools"));
      setSchools(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchClassRooms = async () => {
      const querySnapshot = await getDocs(collection(firestore, "classRooms"));
      setClassRooms(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchClassRooms();
  }, []);

  useEffect(() => {
    if (selectedSchoolId) {
      const filtered = classRooms.filter(classRoom => classRoom.schoolID === selectedSchoolId);
      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(classRooms);
    }
  }, [selectedSchoolId, classRooms]);

  useEffect(() => {
    const filtered = searchQuery
      ? classRooms.filter(course =>
        course.className.toLowerCase().includes(searchQuery.toLowerCase()))
      : classRooms;

    setFilteredCourses(filtered);
  }, [searchQuery, classRooms]);

  const handleSchoolSelect = (schoolId) => {
    setSelectedSchoolId(schoolId);
  };

  const handleDeleteCourse = async (course) => {
    try {
      await deleteCourse(course);
      setFilteredCourses(filteredCourses.filter(c => c.id !== course.id));
    } catch (e) {
      showNotification('danger', 'Failed to delete course');
    }
  };

  const sortCourses = (courses, key, direction) => {
    return courses.slice().sort((a, b) => {
      if (key === 'name') {
        if (a.className < b.className) return direction === 'ascending' ? -1 : 1;
        if (a.className > b.className) return direction === 'ascending' ? 1 : -1;
        return 0;
      }
      if (key === 'date') {
        const dateA = new Date(a.creationDate);
        const dateB = new Date(b.creationDate);
        return direction === 'ascending' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

  const sortedCourses = sortCourses(filteredCourses, sortConfig.key, sortConfig.direction);

  const totalPages = Math.ceil(sortedCourses.length / itemsPerPage);
  const indexOfLastCourse = currentPage * itemsPerPage;
  const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
  const currentCourses = sortedCourses.slice(indexOfFirstCourse, indexOfLastCourse);

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Courses</h1>
          </div>
          <div className="flex space-x-2">
            <AddCourseModal />
            <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
              <input
                type="search"
                id="default-search"
                className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Search courses"
                onChange={handleSearchChange}
              />
            </form>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          {isLoading ? (
            <SkeletonLoader type='row' />
          ) : (
            <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('name')}>Course Name</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>Creation Date</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>School</th>
                  <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('date')}>NumofMembers</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {currentCourses.length > 0 ? (
                  currentCourses.map((course) => (
                    <tr key={course.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.className}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.creationDate ?? '09/01/2024'}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.schoolID}</td>
                      <td className="px-6 py-4 text-gray-900 dark:text-white">{course.attendees ? course.attendees.length : 0}</td>

                      <td className="px-6 py-4 flex items-center space-x-2">
                        <HiOutlineEye
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                          onClick={() => navigate(`/main/classroomdetails/${course.id}`)}
                        />
                        <HiOutlineTrash
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500 dark:hover:text-red-400 h-6 w-6"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (window.confirm(`Are you sure you want to delete "${course.className}"?`)) {
                              handleDeleteCourse(course);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-4">No courses available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="flex justify-center items-center mt-4 p-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            numItemsPerPage={itemsPerPage}
            onNumItemsPerPageChange={handleNumItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

function CoursesTable({ user }) {
  const { courses, introductionCourse } = useAppData();
  const { showPopUp } = usePopUp();
  const { addCourse } = useAppData();

  console.log("Current user role: ", user?.roleName); // Debugging user role

  if (!courses) return;

  const INTRODUCTION_COURSE_ID = "66Fqzp90Cib8lmwCN3QZ";

  // Filter out the introduction course
  const coursesWithoutIntroduction = courses.filter(course => course?.id !== INTRODUCTION_COURSE_ID);

  // Separate published and unpublished courses
  const publishedCourses = coursesWithoutIntroduction.filter(course => course.published);
  const unpublishedCourses = coursesWithoutIntroduction.filter(course => !course.published);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-2">
          <HiOutlineAcademicCap className="text-4xl text-gray-700 dark:text-gray-300" />
          <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Courses</h1>
        </div>
      </div>

      <Accordion
        title="My Resource Library"
        isOpenByDefault={true}
        content={
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Render Introduction Course using the same CourseCard */}
            <CourseCard
              key="introductionCourse"
              course={{
                id: INTRODUCTION_COURSE_ID,
                className: "Introduction to Courseta VR",
                thumbnailColor: introductionCourse?.thumbnailColor || '#808080' // Default thumbnail color
              }}
              user={user}
            />
          </div>
        }
      />

      <Accordion
        title={"Published Courses"}
        isOpenByDefault={false}
        content={
          <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Public Courses</h1>
            <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
              These courses are published, this means all participant can see and use them.
            </p>
            {publishedCourses.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Render published courses */}
                {publishedCourses.map(course => (
                  <CourseCard 
                    key={course.id} 
                    courseTitle={course.className}
                    course={course} 
                    user={user} 
                  />
                ))}

                {/* Add new course card */}
                <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* If no courses, just show the add new course card */}
                <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
              </div>
            )}
          </div>
        }
      />

      {user?.roleName !== 'Student' &&(
        <Accordion
          title={"Unpublished Courses"}
          isOpenByDefault={false}
          content={
            <div className="my-10 p-5 bg-white dark:bg-gray-800 shadow rounded-lg">
              <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white mb-2">Drafted Courses</h1>
              <p className="text-lg text-gray-500 dark:text-gray-400 mb-4">
                These courses are drafts, which means they are not yet published.
              </p>
              <hr className="my-4" />
              {unpublishedCourses.length > 0 ? (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {unpublishedCourses.map(course => (
                    <CourseCard 
                      key={course.id} 
                      courseTitle={course.className}
                      course={course} 
                      user={user} />
                  ))}
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {/* If no courses, just show the add new course card */}
                  <AddNewCourseCard showPopUp={showPopUp} addCourse={addCourse} user={user} />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
}


const AddNewCourseCard = ({ showPopUp, addCourse, user }) => {
  if (user?.roleName === 'Student') {
    return null; // Don't render anything for students
  }

  return (
    <div
      onClick={() => showPopUp(<CreateClassroom onClose={() => showPopUp(null)} addCourse={addCourse} />)}
      className="cursor-pointer rounded-lg bg-white shadow dark:bg-gray-800 p-4 sm:p-6 xl:p-8 flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      <div className="flex-grow flex items-center justify-center">
        <span className="text-blue-500 text-6xl">+</span>
      </div>
      <div className="mt-4 text-center">
        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Add New Course</h3>
        {/* <p className="text-sm text-gray-600 dark:text-gray-400">By Admin</p> */}
      </div>
    </div>
  );
};

const CourseCard = ({ course, courseTitle, user }) => {
  const navigate = useNavigate();
  const { deleteCourse } = useAppData();
  const { showPopUp } = usePopUp();
  // const { user } = useUser();

  // console.log("Current user role in CourseCard: ", user?.roleName); // Debugging user role in course card

  const isIntroductionCourse = course?.id === "66Fqzp90Cib8lmwCN3QZ"; // Assuming this is the ID for Introduction Course

  const handleEditThumbnail = () => {
    showPopUp(
      <CourseThumbnailSettingsPopup
        courseTitle={course.className}
        initialColor={course.thumbnailColor || '#808080'} // Default to grey if color is not set
        courseId={course.id}
        onClose={() => showPopUp(null)}
      />
    );
  };

  return (
    <div className="rounded-lg bg-white shadow dark:bg-gray-800 p-4 sm:p-6 xl:p-8">
      <div className="flex items-center justify-between">
        <div className="flex-grow">
          <span className="text-2xl font-bold leading-none text-gray-900 dark:text-white sm:text-3xl">
            {course?.className}
          </span>
          {isIntroductionCourse ? (
            <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
              Learn how you can use the Web Portal and VR Simulation!
            </h3>
          ) : (
            <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
              {course?.attendees?.length - 1} students
            </h3>
          )}
        </div>
        {/* Render the dropdown only if the user is not a student */}
        {user?.roleName !== 'Student' && (
          <Dropdown arrowIcon={false} label={<HiOutlineDotsVertical className="w-6 h-6 text-gray-600 dark:text-gray-300" />} inline>
            <DropdownItem onClick={() => navigate(`/main/classroomdetails/${course.id}`)}>View Course</DropdownItem>
            {(!isIntroductionCourse || user?.roleName === "Admin") && (
              <DropdownItem onClick={handleEditThumbnail}>Edit Course Thumbnail</DropdownItem>
            )}
            {!isIntroductionCourse && (
              <DropdownItem
                onClick={() => {
                  if (window.confirm(`Are you sure you want to delete "${course.className}"?`)) {
                    deleteCourse(course);
                  }
                }}
              >
                Delete
              </DropdownItem>
            )}
          </Dropdown>
        )}
      </div>
      {/* Apply the thumbnailColor here */}
      <div className="rounded-lg w-full h-56 mt-4" style={{ backgroundColor: course.thumbnailColor || '#808080' }}></div>
      <button
        onClick={() => navigate(`/main/classroomdetails/${course.id}`)}
        className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:text-blue-500 dark:border-gray-600 dark:hover:bg-gray-700"
      >
        View Course
      </button>
    </div>
  );
};


const CourseThumbnailSettingsPopup = ({ onClose, courseTitle, initialColor, courseId }) => {
  // Set the initial state for the course title and color
  const [title, setTitle] = useState(courseTitle || '');
  const [selectedColor, setSelectedColor] = useState(initialColor || '#808080'); // Default color fallback

  const colorOptions = [
    '#FF0000', '#FF7F00', '#FFD700', '#808080', '#008000', '#0000FF',
    '#800080', '#FF1493', '#4B0082', '#FFDAB9', '#A9A9A9', '#FF4500',
  ];

  const handleSave = async () => {
    try {
      // Reference to the course document in Firestore
      const courseRef = doc(firestore, 'classRooms', courseId);

      // Update the Firestore document with new title and color
      await updateDoc(courseRef, {
        className: title,
        thumbnailColor: selectedColor,
      });

      console.log('Course updated successfully');
      onClose(); // Close the popup after saving
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-3">
          <h2 className="text-lg font-semibold">Course Thumbnail Settings</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <HiX className="w-5 h-5" />
          </button>
        </div>

        {/* Body */}
        <div className="mt-4 flex flex-col space-y-4">
          {/* Color Picker */}
          <div>
            <label htmlFor="colorPicker" className="block text-sm font-medium text-gray-700">
              Choose a Color
            </label>
            <div className="flex flex-wrap gap-2 mt-2">
              {colorOptions.map((color, index) => (
                <button
                  key={index}
                  className={`w-8 h-8 rounded-full ${selectedColor === color ? 'ring-4 ring-blue-500' : ''}`}
                  style={{ backgroundColor: color }}
                  onClick={() => setSelectedColor(color)} // Update selected color
                />
              ))}
            </div>
          </div>

          {/* Course Title Input */}
          <div>
              <label htmlFor="courseTitle" className="block text-sm font-medium text-gray-700">
                Course Title
              </label>
              <input
                type="text"
                id="courseTitle"
                className="mt-1 p-2 block w-full border border-gray-300 rounded-md bg-gray-100" // Add a gray background to indicate it's read-only
                value={title}
                readOnly
              />
            </div>

        </div>

        {/* Footer */}
        <div className="flex justify-end mt-4">
          <button
            className="text-gray-600 px-4 py-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
            onClick={handleSave} // Save the title and color to Firestore
          >
            Save Details
          </button>
        </div>
      </div>
    </div>
  );
};
