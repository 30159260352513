import React, { useEffect, useState } from 'react';
import { usePopUp } from '../../../contexts/PopUpContext';
import ScenarioFilter from '../components/ScenarioFilter';
import { HiClipboard, HiDocumentText } from "react-icons/hi";
import UpcomingAssignments from '../../dashboards/components/UpcomingAssignments';
import LatestSubmissions from '../../dashboards/components/LatestSubmissions';
import { useAppData } from '../../../contexts/AppDataContext';
import { useUser } from '../../../contexts/UserContext';

const Overview = ({ course, addScenarios, removeScenarios, handleCreateAssignment }) => {
    const { showPopUp } = usePopUp();
    const { getUserData, grades } = useAppData();
    const user = useUser();
    const [adminInfo, setAdminInfo] = useState(null); // State to hold the admin data
    const [courseAnalytics, setCourseAnalytics] = useState({
        totalAssignments: 0,
        overallSafety: 'N/A',
        overallAccuracy: 'N/A',
        overallCommunication: 'N/A',
        overallAverage: 'N/A',
    });

    // Fetch admin info
    useEffect(() => {
        if (course?.admin) {
            const adminData = getUserData(course.admin); // Fetch admin data
            setAdminInfo(adminData); // Set admin data
        }
    }, [course?.admin, getUserData]);

    // Compute course analytics based on grades
    useEffect(() => {
        if (course && course.assessments) {
            let totalSafety = 0;
            let totalAccuracy = 0;
            let totalCommunication = 0;
            let countSafety = 0;
            let countAccuracy = 0;
            let countCommunication = 0;

            Object.keys(grades).forEach(userId => {
                const userGrades = grades[userId];
                userGrades.forEach(grade => {
                    if (course.assessments.includes(grade.assessmentID)) {
                        if (grade.safety !== undefined) {
                            totalSafety += grade.safety;
                            countSafety++;
                        }
                        if (grade.accuracy !== undefined) {
                            totalAccuracy += grade.accuracy;
                            countAccuracy++;
                        }
                        if (grade.communication !== undefined) {
                            totalCommunication += grade.communication;
                            countCommunication++;
                        }
                    }
                });
            });

            const overallSafety = countSafety > 0 ? (totalSafety / countSafety).toFixed(1) : 'N/A';
            const overallAccuracy = countAccuracy > 0 ? (totalAccuracy / countAccuracy).toFixed(1) : 'N/A';
            const overallCommunication = countCommunication > 0 ? (totalCommunication / countCommunication).toFixed(1) : 'N/A';

            const overallAverage = (countSafety + countAccuracy + countCommunication) > 0
                ? ((totalSafety + totalAccuracy + totalCommunication) / (countSafety + countAccuracy + countCommunication)).toFixed(1)
                : 'N/A';

            setCourseAnalytics({
                totalAssignments: course.assessments.length,
                overallSafety,
                overallAccuracy,
                overallCommunication,
                overallAverage,
            });
        }
    }, [course, grades]);

    // Open the lab filter popup
    const handleOpenLab = () => {
        showPopUp(<ScenarioFilter course={course} addScenarios={addScenarios} removeScenarios={removeScenarios} />);
    };

    return (
        <div className="p-1 min-h-screen w-full">
            {/* Header Section */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-bold text-gray-900">Course Overview</h2>
                {user?.roleName !== 'Student' && (
                <div className="space-x-2">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:ring-4 focus:outline-none"
                        onClick={handleCreateAssignment}
                    >
                        Create Assignment
                        <HiClipboard className="ml-2" />
                    </button>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:ring-4 focus:outline-none"
                        onClick={handleOpenLab}
                    >
                        Open Lab
                        <HiDocumentText className="ml-2" />
                    </button>
                </div>
            )}
            </div>

            {/* Course Overview Section */}
            <div className="bg-white p-6 rounded-lg shadow mb-6">
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Admin</h3>
                        <p className="text-lg font-semibold text-gray-900">
                            {adminInfo ? `${adminInfo.firstName} ${adminInfo.lastName}` : 'No Admin for this course'}
                        </p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Students</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.attendees?.length || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Date Created</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.creationDate || '09/01/2024'}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Assignments</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.assessments?.length || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Grades</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.grades?.length || 0}</p>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Status</h3>
                        <p className="text-lg font-semibold text-gray-900">{course?.published ? 'Published' : 'Not Published'}</p>
                    </div>
                </div>
            </div>

            {/* Course Analytics Section */}
            <div className="bg-white p-6 rounded-lg shadow mb-6">
                <h3 className="text-xl font-bold text-gray-900 mb-4">Course Analytics</h3>
                <div className="grid grid-cols-4 gap-6 text-center">
                    {/* <div>
                        <h4 className="text-lg font-semibold text-gray-900">Total Assignments</h4>
                        <p className="text-2xl font-bold text-blue-600">{courseAnalytics.totalAssignments}</p>
                    </div> */}
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Safety</h4>
                        <p className="text-2xl font-bold text-green-600">{courseAnalytics.overallSafety}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Accuracy</h4>
                        <p className="text-2xl font-bold text-blue-600">{courseAnalytics.overallAccuracy}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Communication</h4>
                        <p className="text-2xl font-bold text-green-600">{courseAnalytics.overallCommunication}%</p>
                    </div>
                    <div>
                        <h4 className="text-lg font-semibold text-gray-900">Overall Average</h4>
                        <p className="text-2xl font-bold text-blue-600">{courseAnalytics.overallAverage}%</p>
                    </div>
                </div>
            </div>

            {/* Upcoming Assignments and Grades Section */}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                {/* Upcoming Assignments */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <UpcomingAssignments courseId={course?.id} limit={10} />
                </div>

                {/* Latest Submissions */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <LatestSubmissions limit={10} courseId={course?.id} />
                </div>
            </div>
        </div>
    );
};

export default Overview;
