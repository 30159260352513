import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const showNotification = (type, message) => {
        const id = new Date().getTime(); // Unique ID for each notification
        setNotifications((prev) => [...prev, { id, type, message }]);
        setTimeout(() => {
            setNotifications((prev) => prev.filter((notif) => notif.id !== id)); // Hide notification after some time
        }, 7000); // Adjust time as needed
    };

    const hideNotification = (id) => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ showNotification, hideNotification, notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};
