import React from "react";
import { useUser } from "../../../contexts/UserContext";
import ProfilePicture from "../../../components/ProfilePicture";

const OpenQuestionCard = ({ question, answer, date, firstName, lastName, photourl}) => {
  const user = useUser();

  return (
    <div className="mb-8 mt-8 p-6 bg-white shadow-lg rounded-md items-center">
      <h2 className="mt-4 text-xl font-bold text-gray-800 mb-4">{question}</h2>
      <article className="text-base pt-6 border-t border-gray-200">
        <footer className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <div className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
              <div className='mr-2'><ProfilePicture userToShow={photourl} size={8} /></div>
              {firstName} {lastName}
            </div>
            <p className="text-sm text-gray-600"><time dateTime={date}>{date}</time></p>
          </div>
        </footer>
        <p className="text-gray-500">{answer}</p>
      </article>
    </div>
  );
}

export default OpenQuestionCard;
