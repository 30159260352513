/* eslint-disable jsx-a11y/img-redundant-alt */
// pages/details/SchoolDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useNotification } from '../../contexts/NotificationContext';
import defaultImage from '../../assets/images/2.png';
import { useUser } from '../../contexts/UserContext';
import { Breadcrumb, Button, Datepicker } from 'flowbite-react';
import { HiHome } from "react-icons/hi";
import { useNavigate } from 'react-router';

export default function SchoolDetails() {
  const [school, setSchool] = useState(null);
  const [allScenarios, setAllScenarios] = useState([]);
  const { schoolId } = useParams();
  const { showNotification } = useNotification();
  const user = useUser();
  const [attendees, setAttendees] = useState([])
  const navigate = useNavigate();


  useEffect(() => {
    const fetchSchoolAndAttendees = async () => {
      const schoolRef = doc(firestore, 'schools', schoolId);
      const schoolSnap = await getDoc(schoolRef);

      if (schoolSnap.exists()) {
        const schoolData = schoolSnap.data();
        setSchool({ ...schoolData, id: schoolSnap.id });
        if (schoolData.attendees && schoolData.attendees.length > 0) {
          const usersCollectionRef = collection(firestore, 'users');
          const q = query(usersCollectionRef, where('id', 'in', schoolData.attendees));
          const querySnapshot = await getDocs(q);
          const attendeesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setAttendees(attendeesList);
          console.log(attendeesList)
        }
      } else {
        showNotification('danger', 'School not found.');
      }
    };

    const fetchScenarios = async () => {
      const scenariosSnapshot = await getDocs(collection(firestore, 'scenarios'));
      const scenariosList = scenariosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllScenarios(scenariosList);
    };

    fetchSchoolAndAttendees();
    fetchScenarios();
  }, [schoolId, showNotification]);

  const handleCapacityChange = (e) => {
    setSchool({ ...school, capacity: e.target.value });
  };

  const handleScenarioChange = (scenarioId) => {
    // Zorg ervoor dat availableScenarios bestaat en een array is
    const availableScenarios = Array.isArray(school.availableScenarios) ? school.availableScenarios : [];

    const updatedScenarios = availableScenarios.includes(scenarioId)
      ? availableScenarios.filter(id => id !== scenarioId)
      : [...availableScenarios, scenarioId];

    setSchool({ ...school, availableScenarios: updatedScenarios });
  };


  const handleUpdateSchool = async () => {
    try {
      const schoolRef = doc(firestore, 'schools', schoolId);
      await updateDoc(schoolRef, {
        ...school,
        // Om veiligheidsredenen, update alleen de velden die veranderd mogen worden.
        capacity: Number(school.capacity),
        availableScenarios: school.availableScenarios,
      });
      showNotification('success', 'School updated successfully.');
    } catch (error) {
      showNotification('danger', 'Failed to update school.');
    }
  };

  if (!school) return <div>Loading...</div>;

  return (
    <div>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href='#' onClick={(e) => {
                e.preventDefault();
                navigate("/main/dashboard")
              }}>
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Scenarios</Breadcrumb.Item>
              <Breadcrumb.Item>{school?.name ?? "Unnamed School"}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {school?.name ?? "Unnamed School"}
            </h1>
          </div>
          <div className="block items-center sm:flex mt-8 mb-4">
            <div className="flex w-full items-center">
              <button onClick={handleUpdateSchool} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Update School
              </button>
              {/* <button type="button" onClick={handleAddScenarioClick}
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-2 -ml-1" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path
                d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
            </svg>
            Add new Scenario
          </button> */}
            </div>

          </div>
        </div>

      </div>
      <br></br>
      <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">{school.name}</h1>
        <div className="mb-4">
          <label htmlFor="capacity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Capacity:</label>
          <input
            type="number"
            id="capacity"
            value={school.capacity}
            onChange={handleCapacityChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          />
        </div>
        <p className="text-sm font-medium text-gray-900 dark:text-gray-300">Members: {school.numOfMembers}</p>
        <div>
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Available Scenarios:</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
            {allScenarios.map(scenario => (
              <label key={scenario.id} className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  checked={school?.availableScenarios?.includes(scenario.id)}
                  onChange={() => handleScenarioChange(scenario.id)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{scenario.name}</span>
              </label>
            ))}
          </div>
        </div>


        {/* Attendees List */}
        {user?.roleName !== "Student" && (
          <div className="p-4 mt-5 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
            <h3 className="text-xl font-semibold dark:text-white">Attendees</h3>
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {attendees.length > 0 ? (
                attendees.map((attendee, index) => (
                  <li key={index} className="py-3 sm:py-4">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img className="w-8 h-8 rounded-full" src={attendee.photo_url || defaultImage} alt={`${attendee.firstName} ${attendee.lastName} profile image`} />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          {attendee.firstName} {attendee.lastName}
                        </p>
                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                          {attendee.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-center py-12">
                  <img className="w-32 h-32 mx-auto" src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg" alt="No attendees" />
                  <p className="text-gray-700 dark:text-white font-medium text-lg">No Attendees to display.</p>
                  <p className="text-gray-500 dark:text-gray-400">Fill this school by sending invites!</p>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}  