import { useEffect, useRef, useState } from "react";
import { useScripting } from "../contexts/ScriptingContext";
import { prettyPrintEnum } from "./ScriptingEnumLibrary";

export const ScriptingIntegerInput = ({ id, data, label }) => {
    const { updateNodeData, removeNodeData } = useScripting();
    const [inputValue, setInputValue] = useState(data?.[label] || 0);

    // useEffect(() => {
    //     const parsedValue = parseFloat(inputValue);
    //     if (!isNaN(parsedValue)) {
    //         updateNodeData(id, { [label]: parsedValue });
    //     }

    //     return () => {
    //         // Cleanup logic
    //         console.log(`Component ${label} is being unmounted`);
    //         removeNodeData(id, label);
    //         // Perform any necessary cleanup herea
    //     };
    // }, [inputValue]);

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        const parsedValue = parseFloat(inputValue);
        if (!isNaN(parsedValue)) {
            updateNodeData(id, { [label]: parsedValue });
        }

        return () => {
            // Cleanup logic
            // console.log(`Component ${label} is being unmounted`);
            removeNodeData(id, label);
            // Perform any necessary cleanup herea
        };
    }, [id, label])

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        const parsedValue = parseFloat(inputValue);
        if (!isNaN(parsedValue)) {
            updateNodeData(id, { [label]: parsedValue });
        }
    }, [inputValue, id, label]);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
    };

    return (
        <input
            type="number"
            step="0.1"
            value={inputValue}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
        />
    );
};

export const ScriptingEnumLabel = ({ id, data, label, enumType }) => {
    const { updateNodeData, removeNodeData } = useScripting();
    const [inputValue, setInputValue] = useState(data?.[label] ?? 0);

    useEffect(() => {
        if (inputValue >= Object.values(enumType).length || Array.isArray(inputValue)) {
            setInputValue(0);
        }

        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: inputValue });

        return () => {
            // Cleanup logic
            // console.log(`Component ${label} is being unmounted`);
            removeNodeData(id, label);
            // Perform any necessary cleanup herea
        };
    }, [id, label, enumType])

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: inputValue });
    }, [inputValue, id, label, enumType]);

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setInputValue(value);
    };

    return (
        <select
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
            value={inputValue}
            onChange={handleChange}
        >
            {Object.entries(enumType).map(([key, value]) => (
                <option key={value} value={value}>
                    {prettyPrintEnum(key)}
                </option>
            ))}
        </select>
    );
};

export const ScriptingEnumLabelMultiple = ({ id, data, label, enumType, prompt = "Select options" }) => {
    const { updateNodeData, removeNodeData } = useScripting();
    const [selectedOptions, setSelectedOptions] = useState(Array.from(data?.[label] ?? []) ?? []);
    const optionsListRef = useRef(null);

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: selectedOptions });

        return () => {
            // Cleanup logic
            // console.log(`Component ${label} is being unmounted`);
            removeNodeData(id, label);
            // Perform any necessary cleanup herea
        };
    }, [id, label])

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: selectedOptions });
    }, [selectedOptions, id, label]);

    const handleChange = (e) => {
        const isChecked = e.target.checked;
        const option = parseInt(e.target.value);

        const selectedOptionSet = new Set(selectedOptions);

        if (isChecked) {
            selectedOptionSet.add(option);
        } else {
            selectedOptionSet.delete(option);
        }

        const newSelectedOptions = Array.from(selectedOptionSet);
        setSelectedOptions(newSelectedOptions);
        // updateNodeData();
        // onChange(newSelectedOptions);
    };

    return (
        <label className="relative block w-full">
            <input type="checkbox" className="hidden peer" />

            <div className="cursor-pointer after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex border rounded px-4 py-2 dark:bg-gray-700 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-600 w-full justify-between items-center">
                {prompt}
                {selectedOptions.length > 0 && (
                    <span className="ml-2 text-blue-500">{`(${selectedOptions.length} selected)`}</span>
                )}
            </div>

            <div className="absolute bg-white dark:bg-gray-800 border dark:border-gray-700 shadow-lg transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60  z-10 mt-1 rounded-md">
                <ul ref={optionsListRef} className="max-h-60 overflow-y-auto p-2">
                    {Object.entries(enumType).map(([key, option]) => (
                        <li key={option} className="p-1">
                            <label className="flex items-center whitespace-nowrap cursor-pointer px-3 py-2 transition-colors hover:bg-blue-100 dark:hover:bg-gray-700 rounded-md">
                                <input
                                    type="checkbox"
                                    name={label}
                                    value={option}
                                    checked={selectedOptions.includes(option)}
                                    className="cursor-pointer"
                                    onChange={handleChange}
                                />
                                <span className="ml-2">{prettyPrintEnum(key)}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </label>
    );

};

export const ScriptingStringInput = ({ id, data, label }) => {
    const { updateNodeData, removeNodeData } = useScripting();
    const [inputValue, setInputValue] = useState(data?.[label] || "");

    // useEffect(() => {
    //     updateNodeData(id, { [label]: inputValue });

    //     return () => {
    //         // Cleanup logic
    //         console.log(`Component ${label} is being unmounted`);
    //         removeNodeData(id, label);
    //         // Perform any necessary cleanup herea
    //     };
    // }, [inputValue]);

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: inputValue });

        return () => {
            // Cleanup logic
            // console.log(`Component ${label} is being unmounted`);
            removeNodeData(id, label);
            // Perform any necessary cleanup herea
        };
    }, [id, label])

    useEffect(() => {
        // console.log(`Component ${label} is being mounted`);
        updateNodeData(id, { [label]: inputValue });
    }, [inputValue, id, label]);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
    };

    return (
        <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg"
        />
    );
};