import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssessment } from './contexts/AssessmentContext';
import { useAppData } from '../../contexts/AppDataContext';
import ProfilePicture from '../../components/ProfilePicture';
import { Dropdown, DropdownItem } from 'flowbite-react';

export default function AssessmentDetails() {
    const { assessment } = useAssessment();
    const { courses, getUserListData, grades } = useAppData();
    const [students, setStudents] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {
        console.log('AssessmentDetails rendered with assessment:', assessment);
        if (assessment) {
            const course = courses.find((course) => course.id === assessment.classroomId);
            if (course && course.attendees) {
                const users = getUserListData(course.attendees);
                setStudents(users);
            }
        }
    }, [assessment, courses, getUserListData]);

    const doesGradeExist = (userId) => {
        return (getGrade(userId) != null);
    }

    const calculateCategoryPercentages = (grade) => {
        const desiredCategories = ['Communication', 'Accuracy', 'Safety'];

        const percentages = desiredCategories.reduce((acc, category) => {
            const categoryData = grade[category];
            if (categoryData) {
                let totalQuestions = 0;
                let correctAnswers = 0;

                Object.entries(categoryData).forEach(([key, value]) => {
                    const [achievedScore, maxScore] = value.split('/').map(Number);
                    totalQuestions += maxScore;
                    correctAnswers += achievedScore;
                });

                acc += (correctAnswers / totalQuestions) * 100;
            } else {
                acc += 0; // Als de categorie niet bestaat, stel het percentage op 0.
            }
            return acc;
        }, 0);

        return (percentages / desiredCategories.length).toFixed(2);
    };

    const getGradeAverage = (userId) => {
        const grade = getGrade(userId);
        if (grade) {
            return calculateCategoryPercentages(grade);
        }
    }

    const getGrade = (userId) => {
        const userGrades = grades[userId];
        if (userGrades?.length > 0) {
            const grade = userGrades.find((grade) => grade.assessmentID === assessment.id);
            if (grade) return grade;
            else return null;
        }
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
            <div className="px-8 pt-6">
                <h3 className="mb-4 self-center text-2xl font-bold whitespace-nowrap dark:text-white">
                    {assessment?.assignmentName || 'Assignment Name'}
                </h3>
                <div className="bg-white dark:bg-gray-800 shadow-md sm:rounded-lg overflow-hidden">
                    <div className="border-b border-gray-200 dark:border-gray-700 mx-4 pb-4">
                        <div className="flex items-center justify-between space-x-4 pt-3">
                            <div className="flex-1 flex items-center space-x-3">
                                <h5 className="dark:text-white font-semibold">Submissions</h5>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
                                <form className="w-full md:max-w-sm flex-1 md:mr-4" onSubmit={(e) => e.preventDefault()}>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3"></div>
                                        <input
                                            type="search"
                                            id="default-search"
                                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Search Users..."
                                            onChange={() => { }}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="p-4 text-left">Student</th>
                                    <th scope="col" className="p-4 text-left">Submitted</th>
                                    <th scope="col" className="p-4 text-left">Result</th>
                                    <th scope="col" className="px-4 py-3 text-right"><span className="sr-only">Actions</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {students.map((user) => (
                                    <tr key={user.id} className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
                                        <td className="px-6 py-4 whitespace-nowrap align-middle">
                                            <div className="flex items-center">
                                                <div className="p-2">
                                                    <ProfilePicture userToShow={user} />
                                                </div>
                                                <div>
                                                    <div className="font-medium text-gray-900 dark:text-white">{`${user.firstName} ${user.lastName}`}</div>
                                                    <div className="text-gray-500">{user.email}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap align-middle">
                                            <div className="flex items-center">
                                                {doesGradeExist(user.id) ? <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Submitted</span> : <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Not submitted</span>}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap align-middle">
                                            <div className="flex items-center">
                                                {doesGradeExist(user.id) ? `${getGradeAverage(user.id)}%` : "N/A"}
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-right">
                                            <Dropdown label="Actions" inline>
                                                {doesGradeExist(user.id) ? <DropdownItem onClick={() => { navigate(`/main/gradingDetails/${getGrade(user.id).id}`) }}>View report</DropdownItem> : <div></div>}
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}
