import React, { useEffect, useState } from 'react';
import defaultImage from '../../assets/images/22.png';
import { usePopUp } from '../../contexts/PopUpContext';
import InviteUser from '../creators/CreateUser';
import UserInfo from '../user_profile/UserInfo_Popup';
import { useUser } from '../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import { HiUsers, HiPlus, HiOutlineTrash, HiOutlineEye } from 'react-icons/hi';
import { Table } from 'flowbite-react';
import Pagination from '../../components/Pagination';
import { useOverview } from '../../contexts/OverviewContext';
import { useAuthStatus, getUserData } from '../../data/GetUserData';
import SkeletonLoader from '../../components/SkeletonLoader';

export default function UsersOverview() {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredPendingUsers, setFilteredPendingUsers] = useState([]);
  const [showPending, setShowPending] = useState(false); // State to toggle between users and pending registrations
  const { users, pendingUsers, deleteUser, isLoading, deletePendingUser } = useAppData();
  const { showPopUp } = usePopUp();
  const user = useUser();
  const {
    searchQuery,
    currentPage,
    itemsPerPage,
    handleSearchChange,
    handlePageChange,
    handleNumItemsPerPageChange,
    setSearchQuery,
    setSortConfig,
    setCurrentPage
  } = useOverview();

  useEffect(() => {
    setSearchQuery('');
    setSortConfig({ key: 'name', direction: 'ascending' });

    return () => {
      setSearchQuery('');
      setSortConfig({ key: 'name', direction: 'ascending' });
    };
  }, [setSearchQuery, setSortConfig, setCurrentPage]);

  const [currentUserData, setCurrentUserData] = useState(null);
  const { currentUser, fetchingData } = useAuthStatus();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          const data = await getUserData(currentUser.uid);
          console.log('Fetched user data:', data);
          setCurrentUserData(data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (currentUserData) {
      console.log('Current User Data:', currentUserData);
    }

    if (!showPending) {
      // Filter confirmed users (non-pending)
      const confirmedUsers = users.filter(u => !u.isPending);
      const filters = searchQuery
        ? confirmedUsers.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : confirmedUsers;
      setFilteredUsers(filters);
    } else {
      // If current user is Teacher, only show pending users with the same schoolID
      let filteredPending;
      if (currentUserData && currentUserData.roleName === 'Teacher') {
        filteredPending = pendingUsers.filter(
          (user) => user.schoolID === currentUserData.schoolID
        );
      } else {
        // Admins or other roles can see all pending users
        filteredPending = pendingUsers;
      }

      const filters = searchQuery
        ? filteredPending.filter(
          (user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : filteredPending;

      setFilteredPendingUsers(filters);
    }
  }, [users, pendingUsers, searchQuery, showPending, currentUserData]);

  const handleAddUserClick = () => {
    showPopUp(<InviteUser onClose={() => showPopUp(null)} currentUser={user} />);
  };

  const handleShowUserClick = (userId) => {
    const userToShow = users.find(user => user.id === userId);
    showPopUp(<UserInfo user={userToShow} onClose={() => showPopUp(null)} currentUserRole={user.roleName} />);
  };

  // Determine which users to display based on the view
  const displayUsers = showPending ? filteredPendingUsers : filteredUsers;

  // Pagination logic
  const totalPages = Math.ceil(displayUsers.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = displayUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleDelete = (user) => {
    // Check if we are in the "Pending Registrations" tab
    if (showPending) {
      console.log(`Deleting pending user: ${user.firstName} ${user.lastName}`);
      deletePendingUser(user); // Use the deletePendingUser function for pending users
    } else {
      console.log(`Deleting confirmed user: ${user.firstName} ${user.lastName}`);
      deleteUser(user); // Use the deleteUser function for confirmed users
    }
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        {/* Toggle between Users and Pending Registrations */}
        <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <HiUsers className="text-4xl text-gray-700 dark:text-gray-300" />
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              {showPending ? 'Pending Registrations' : 'Users'}
            </h1>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={() => setShowPending(false)}
              type="button"
              className={`px-4 py-2 rounded-lg font-medium shadow-sm ${!showPending ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 dark:bg-gray-700 dark:text-white'} `}
            >
              Users
            </button>
            {currentUserData && currentUserData.roleName !== 'Student' && (
              <button
                onClick={() => setShowPending(true)}
                type="button"
                className={`px-4 py-2 rounded-lg font-medium shadow-sm ${showPending ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 dark:bg-gray-700 dark:text-white'} `}
              >
                Pending Registrations
              </button>
            )}
          </div>
        </div>

        {/* Search Bar and Add User Button */}
        <div className="flex justify-between items-center mb-4">
          <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
            <input
              type="search"
              id="default-search"
              className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Search users"
              onChange={handleSearchChange}
            />
          </form>

          {currentUserData && currentUserData.roleName && currentUserData.roleName !== 'Student' && (
            <button
              onClick={handleAddUserClick}
              type="button"
              className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
            >
              <HiPlus className="h-5 w-5 mr-2" />
              Invite User
            </button>
          )}
        </div>

        {/* User/Pending User Table */}
        <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
          {isLoading ? (
            <SkeletonLoader type="row" />
          ) : (
            <Table hoverable={true}>
              <Table.Head>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">User</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">School</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Role</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Date Joined</Table.HeadCell>
                <Table.HeadCell className="text-gray-700 dark:text-gray-300">Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y divide-gray-200 dark:divide-gray-700">
                {currentUsers.map((user) => (
                  <Table.Row key={user.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                    <Table.Cell className="flex items-center space-x-4">
                      <img src={user.photo_url || defaultImage} alt={`${user.firstName} ${user.lastName}`} className="h-10 w-10 rounded-full object-cover" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {`${user.firstName} ${user.lastName}`}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-300">{user.email}</div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <span className="text-sm text-gray-900 dark:text-white">{user.schoolName || 'N/A'}</span>
                    </Table.Cell>
                    <Table.Cell>
                      {user.isPending ? (
                        <span
                          className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300"
                        >
                          Pending
                        </span>
                      ) : (
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.roleName.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.roleName.trim() === 'Student'
                              ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                              : user.roleName.trim() === 'Admin'
                                ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}
                        >
                          {user.roleName.trim() || 'N/A'}
                        </span>
                      )}
                    </Table.Cell>

                    <Table.Cell className="text-sm text-gray-500 dark:text-gray-300">
                      {user.datejoined ?? '09/01/2024'}
                    </Table.Cell>
                    <Table.Cell className="flex items-center space-x-2">
                      <HiOutlineEye
                        className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 dark:hover:text-blue-400 h-6 w-6"
                        onClick={() => handleShowUserClick(user.id)}
                      />
                      {currentUserData && currentUserData.roleName === "Admin" && (
                        <HiOutlineTrash
                          className="text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500 dark:hover:text-red-400 h-6 w-6"
                          onClick={() => {
                            if (window.confirm(`Are you sure you want to delete ${user.firstName} ${user.lastName}?`)) {
                              handleDelete(user);
                            }
                          }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>

        <div className="flex justify-center items-center mt-4 p-6">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            numItemsPerPage={itemsPerPage}
            onNumItemsPerPageChange={handleNumItemsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
}
