import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useNavigate } from 'react-router';
import { usePopUp } from '../../contexts/PopUpContext';
import CreateSchool from '../creators/CreateSchool';
import { Breadcrumb, Button, Datepicker } from 'flowbite-react';
import { HiHome } from "react-icons/hi";
import { useNotification } from '../../contexts/NotificationContext';
import { useAppData } from '../../contexts/AppDataContext';
import SkeletonLoader from '../../components/SkeletonLoader';

export default function SchoolsOverview() {
  const [schools, setSchools] = useState([]);
  const navigate = useNavigate();
  const { showPopUp } = usePopUp();
  const { showNotification } = useNotification();
  const { isLoading } = useAppData();

  useEffect(() => {
    const fetchSchools = async () => {
      const schoolsCollectionRef = collection(firestore, 'schools');
      const schoolsSnapshot = await getDocs(schoolsCollectionRef);
      const schoolsList = schoolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSchools(schoolsList);
    };

    fetchSchools();
  }, []);


  const handleAddSchoolClick = () => {
    showPopUp(<CreateSchool onClose={() => showPopUp(null)} />);
  };

  const refreshSchoolAttendees = async () => {
    const usersCollectionRef = collection(firestore, 'users');
    const usersSnapshot = await getDocs(usersCollectionRef);

    // Map van school ID naar lijst van user IDs
    const schoolAttendeesMap = {};

    usersSnapshot.forEach((userDoc) => {
      const userData = userDoc.data();
      if (userData.schoolID && !schoolAttendeesMap[userData.schoolID]) {
        schoolAttendeesMap[userData.schoolID] = [];
      }
      if (userData.schoolID) {
        schoolAttendeesMap[userData.schoolID].push(userDoc.id);
      }
    });

    // Bijwerken van de 'attendees' array in elke school document
    for (const [schoolID, attendees] of Object.entries(schoolAttendeesMap)) {
      const schoolDocRef = doc(firestore, 'schools', schoolID);
      await updateDoc(schoolDocRef, {
        attendees: attendees,
        numOfMembers: attendees.length,
      });
    }

    // Refresh de lokale staat van de scholen
    const schoolsCollectionRef = collection(firestore, 'schools');
    const schoolsSnapshot = await getDocs(schoolsCollectionRef);
    const schoolsList = schoolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSchools(schoolsList);
    showNotification('success', 'All schools and data are updated');
  };


  return (
    <div>

      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href='#' onClick={(e) => {
                e.preventDefault();
                navigate("/main/dashboard")
              }}>
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Schools</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              COURSETA Schools
            </h1>
          </div>
          <div className="flex items-center justify-between gap-6 sm:flex mt-8 mb-4">
            <div className="flex w-full items-center">
              <button type="button" onClick={handleAddSchoolClick}
                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 mr-20">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-2 -ml-1" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                Add new School
              </button>


            </div>
          </div>

        </div>
        <div className="flex flex-col items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
          <button type="button" onClick={refreshSchoolAttendees} className="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-2 -ml-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
            </svg>
            Refresh School Data
          </button>
          {/* <p className="text-xs mt-2 text-center">Use this as Admin to refresh all the current school data</p> */}
        </div>
      </div>
      <br></br>


      {isLoading ? (
        <SkeletonLoader type='card' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
          {schools.map((school) => (
            <div key={school.id} className="p-5 space-y-4 bg-white border border-gray-200 rounded-lg shadow-md lg:p-8 dark:bg-gray-800 dark:border-gray-700">
              <h3 className="text-lg font-bold leading-tight text-gray-900 dark:text-white">
                {school.name}
              </h3>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                Members: {school.numOfMembers}
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-1">
                <div className="bg-primary-600 h-2.5 rounded-full" style={{ width: `${(school.numOfMembers / school.capacity) * 100}%` }}></div>
              </div>
              <div className="flex items-center justify-between gap-6">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {school.numOfMembers} of {school.capacity} capacity
                </span>

                <span className="text-xs font-normal text-right text-gray-500 dark:text-gray-400">
                  {school?.availableScenarios?.length} scenarios
                </span>
              </div>

              <div className="flex flex-col items-start gap-2">
                <button
                  onClick={() => navigate(`/main/schoolDetails/${school.id}`)}
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  See more
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m3 4L9 9" />
                  </svg>
                </button>

                <br></br>
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  CreationDate: {school.creationDate ?? 'N/A'}
                </span>
              </div>
            </div>
          ))}
        </div>

      )}
    </div>

  );
}
