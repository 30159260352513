import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { HiOutlineCalendar } from 'react-icons/hi';

export default function UpcomingAssignments({ courseId = null, limit }) { // Optional courseId
  const { assignments, grades, courses } = useAppData(); 
  const user = useUser();
  const navigate = useNavigate();
  const [upcomingAssignments, setUpcomingAssignments] = useState([]);
  const [submissionsCount, setSubmissionsCount] = useState({});

  useEffect(() => {
    let filteredAssignments = assignments.filter(
      (assignment) => !assignment.isDeleted
    );

    if (courseId) {
      // Filter assignments by courseId if provided
      filteredAssignments = filteredAssignments.filter(
        (assignment) => assignment.classroomId === courseId
      );
    }

    if (grades) {
      const userGrades = grades[user.id];
      const incompleteAssignments = userGrades && userGrades.length > 0
        ? filteredAssignments.filter((assignment) => 
            !userGrades.find((grade) => grade.assessmentID === assignment.id)
          )
        : filteredAssignments;

      const sortedAssignments = incompleteAssignments
        .filter(assignment => assignment.dueDate)
        .sort((a, b) => new Date(b.dueDate.seconds * 1000).getTime() - new Date(a.dueDate.seconds * 1000).getTime())
        .slice(0, limit);

      setUpcomingAssignments(sortedAssignments);
    } else {
      setUpcomingAssignments(
        filteredAssignments.slice(0, 5) // Limit to 5 upcoming assignments
      );
    }
  }, [assignments, grades, user.id, courseId]);

  useEffect(() => {
    if (grades && assignments.length > 0) {
      const submissionCounts = {};

      assignments.forEach((assignment) => {
        const count = Object.values(grades).reduce((acc, userGrades) => {
          const test = userGrades?.reduce((sum, grade) => {
            sum += (grade.assessmentID === assignment.id ? 1 : 0);
            return sum;
          }, 0);
          return acc + test;
        }, 0);

        submissionCounts[assignment.id] = count;
      });

      setSubmissionsCount(submissionCounts);
    }
  }, [grades, assignments]);

  const formatDueDate = (dueDate) => {
    if (!dueDate) return 'No Due Date';
    return new Date(dueDate.seconds * 1000).toLocaleString();
  };

  const getLabelColor = (dueDate) => {
    const now = new Date();
    const assignmentDate = new Date(dueDate.seconds * 1000);
    const timeDifference = assignmentDate - now;

    if (timeDifference < 0) return 'text-red-500';
    if (timeDifference <= 24 * 60 * 60 * 1000) return 'text-orange-500';
    if (timeDifference <= 7 * 24 * 60 * 60 * 1000) return 'text-blue-500';
    return 'text-green-500';
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-700">Upcoming Assignments</h3>
        <button
          className="text-blue-500 hover:underline"
          onClick={() => navigate('/main/assesmentsoverview')}
        >
          See All →
        </button>
      </div>
      {upcomingAssignments.length > 0 ? (
        <ul>
          {upcomingAssignments.map((assignment) => (
            <li key={assignment.id} className="flex items-center justify-between py-2 border-b border-gray-200">
              <div className="flex items-center">
                <HiOutlineCalendar className="text-gray-500 h-5 w-5 mr-3" />
                <div>
                  <p className="text-gray-800 font-semibold">{assignment.assignmentName}</p>
                  <p className={`text-sm ${getLabelColor(assignment.dueDate)}`}>
                    Due {formatDueDate(assignment.dueDate)}
                  </p>
                </div>
              </div>
              <div className="text-sm text-gray-600">
                {submissionsCount[assignment.id] || 0} Submissions
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No upcoming assignments.</p>
      )}
    </div>
  );
}
