import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { useAppData } from "../../../contexts/AppDataContext";
import { useLocation, useParams } from "react-router";
import { addDocument, updateDocument, uploadFile, existsDocument } from "../../../services/FirebaseService";
import { assignmentCollection, coursesCollection, scenariosCollection } from "../../../firebase";
import { arrayUnion } from "@firebase/firestore";
import { useCourse } from "../../courses/contexts/CourseContext";
import { useNotification } from "../../../contexts/NotificationContext";
import { usePopUp } from "../../../contexts/PopUpContext";

const ScenarioContext = createContext();

export function useScenario() {
    return useContext(ScenarioContext);
}

export function ScenarioProvider({ children }) {
    const [scenario, setScenario] = useState(null);
    const { scenarios, courses, setCourses, templates, setScenarios } = useAppData();
    const { scenarioId } = useParams();
    const location = useLocation();
    const { classroomId, assignmentName } = location.state || {};
    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    const [files, setFiles] = useState({})

    const user = useUser();

    useEffect(() => {
        if (scenarios && scenarioId) {
            //Copy the base scenario
            const scenario = scenarios.find(s => s.id === scenarioId);
            if (scenario) {
                let s = { ...scenario };
                if (assignmentName) {
                    s.assignmentName = assignmentName;
                    s.classroomId = classroomId;
                    s.scenarioID = scenarioId;
                    s.dueDate = new Date();
                }
                setScenario(s);
            }
            else if (templates) {
                const scenario = templates.find(s => s.id === scenarioId);
                if (scenario) {
                    let s = { ...scenario };
                    s.assignmentName = assignmentName;
                    s.classroomId = classroomId;
                    s.scenarioID = scenarioId;
                    setScenario(s);
                }
            }
        }
    }, [scenarios, scenarioId]);

    const addField = (field, path) => {
        let s = { ...scenario };
        const prev = s[path];
        s[path] = [...prev, field]
        setScenario(s);
        console.log("Updated scenario");
        console.log(s);
    }

    const removeField = (field, path) => {
        //TODO
    }

    const setField = (field, path) => {
        let s = { ...scenario };
        s[path] = field;
        setScenario(s);
        console.log("Updated scenario");
        console.log(s);
    }

    const setFile = (file, path) => {
        let f = { ...files };
        f[path] = file;
        setFiles(f);

        let filePath;
        filePath = `${scenario.assignmentName ? "teacherAssignments" : "scenarios"}/${scenario?.id ?? "undefined_scenario"}/${file.name}`;

        let s = { ...scenario };
        s[path] = filePath;
        setScenario(s);
        console.log("Updated scenario with file path:", filePath);
    };

    const getFile = (path) => {
        return files[path];
    }

    const save = async () => {
        try {
            if (scenario) {
                let id = 0;
                if (scenario.assignmentName) {
                    console.log(scenario.assignmentName);
                    id = await addDocument(scenario, assignmentCollection);
                }
                else {
                    delete scenario.assignmentName;
                    delete scenario.dueDate;
                    delete scenario.classroomId;
                    delete scenario.scenarioID;
                    id = scenario.id;
                    await updateDocument(id, scenariosCollection, scenario);
                    setScenarios(scenarios.map((s) => s.id === id ? scenario : s));
                }

                if (classroomId) {
                    await updateDocument(classroomId, coursesCollection, { assessments: arrayUnion(id) });
                    const newCourses = courses.map(c => c.id !== classroomId ? c : { ...c, assessments: [...c.assessments ?? [], id] });
                    setCourses(newCourses);
                }
                showNotification('success', 'Scenario saved!');
                console.log("Saved to: " + (scenario.assignmentName ? "assignments" : "scenarios") + ", with ID: " + id);

                for (const key of Object.keys(files)) {
                    const path = scenario[key].split('/').slice(0, -1).join('/');
                    await uploadFile(files[key], path);
                    console.log("Uploaded file: " + key + " to: " + path);
                }
            }
        } catch (e) {
            showNotification('danger', 'Scenario Failed to Save!');
            console.e("error saving scenario", e);
        }
    }

    // Dit moet anders/beter ben ik bang
    const addPatient = (patient) => {
        let s = { ...scenario };
        if (!s.patients) {
            s.patients = [];
        }
        s.patients.push(patient);
        setScenario(s);
        console.log("Patient added to scenario", s);
    };

    const updatePatientField = (index, field, value) => {
        let s = { ...scenario };
        if (s.patients && s.patients[index]) {
            s.patients[index] = { ...s.patients[index], [field]: value };
        } else {
            console.error("Patient index out of bounds");
        }
        setScenario(s);
        console.log("Updated patient field", s);
    };

    return (
        <ScenarioContext.Provider value={{ scenario, setScenario, addField, setField, removeField, setFile, getFile, save, addPatient, updatePatientField }}>
            {children}
        </ScenarioContext.Provider>
    );
}
