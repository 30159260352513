import React, { useState } from 'react';
import { firestore, scenariosCollection, schoolsCollection } from '../../firebase';
import { useNotification } from '../../contexts/NotificationContext';
import { arrayUnion } from '@firebase/firestore';
import { addDocument, updateDocument } from '../../services/FirebaseService';
import { useAppData } from '../../contexts/AppDataContext';

export default function CreateScenario({ onClose }) {
  const [name, setName] = useState("");
  const { showNotification } = useNotification();

  const { setScenarios, school, setSchool } = useAppData();

  const handleSubmit = async (event) => {
    event.preventDefault();

    let scenario = {
      name: name,
      chosenQuestionnaireId: "",
      creationDate: new Date(),
      debriefing: [],
      prebriefing: "",
      debriefing_2: [],
      reflection: []
    };
    const id = await addDocument(scenario, scenariosCollection);
    scenario.id = id;

    setScenarios((prev) => [...prev, scenario]);

    await updateDocument(school.id, schoolsCollection, { availableScenarios: arrayUnion(scenario.id) });
    setSchool((prev) => ({ ...prev, availableScenarios: [...prev.availableScenarios, scenario.id] }));

    onClose();
    /*  showNotification('success', 'Scenario created successfully!');
      onClose(); // Close the modal
    } catch (error) {
      showNotification('danger', 'Failed to create scenario.');
      console.error("Error writing document: ", error);
    }*/
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 dark:bg-gray-700 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full dark:bg-gray-800">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white" id="modal-title">
                  Create New Scenario
                </h3>
                <div className="mt-2">
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="scenarioName" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Scenario Name</label>
                      <input type="text" id="scenarioName" value={name} onChange={(e) => setName(e.target.value)} required className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-300" />
                    </div>
                    <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800">
                      Create Scenario
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-700">
            <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-500" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
