import React, { useEffect, useState } from 'react';
import { useAppData } from '../../contexts/AppDataContext';
import { usePopUp } from '../../contexts/PopUpContext';
import { useNavigate } from 'react-router-dom';
import { useOverview } from '../../contexts/OverviewContext';
import { HiPencil, HiTrash } from 'react-icons/hi';
import Pagination from '../../components/Pagination';

function ConditionScripting() {
    const { conditions, addCondition, deleteCondition } = useAppData();
    const { showPopUp, hidePopUp } = usePopUp();
    const navigate = useNavigate();
    const {
        searchQuery,
        currentPage,
        itemsPerPage,
        handleSearchChange,
        handlePageChange,
        handleNumItemsPerPageChange,
        setSearchQuery,
        setSortConfig,
        setCurrentPage,
        sortConfig,
        handleSort
      } = useOverview();
    
    
      useEffect(() => {
        setSearchQuery('');
        setSortConfig({ key: 'name', direction: 'ascending' });
        //setCurrentPage(1); // Optioneel: reset de huidige pagina
    
        return () => {
          // Optioneel: reset bij demontage van de component
          setSearchQuery('');
          setSortConfig({ key: 'name', direction: 'ascending' });
          //setCurrentPage(1);
        };
      }, [setSearchQuery, setSortConfig, setCurrentPage]);

    useEffect(() => {
        console.log(conditions);
    }, [conditions]);

    const handleEdit = (id) => {
        navigate(`/main/conditionsoverview/${id}/visualScripting`);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this condition?');
        if (confirmDelete) {
            await deleteCondition(id);
        }

    };

    const handleSave = async (event, newConditionName) => {
        event.preventDefault();
        const id = await addCondition({ name: newConditionName });
        hidePopUp();
        navigate(`/main/conditionsoverview/${id}/visualScripting`);
    };

    const handleCreate = () => {
        showPopUp(
            <CreateConditionPopUp onContinue={handleSave} onClose={hidePopUp} />
        );
    };

    const filteredConditions = searchQuery
        ? conditions.filter((condition) =>
            condition.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : conditions;

    const sortConditions = (conditions, key, direction) => {
        const sortedConditions = conditions.slice().sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        return sortedConditions;
    };

    const sortedConditions = sortConditions(filteredConditions, sortConfig.key, sortConfig.direction);

    const totalPages = Math.ceil(sortedConditions.length / itemsPerPage);
    const indexOfLastCondition = currentPage * itemsPerPage;
    const indexOfFirstCondition = indexOfLastCondition - itemsPerPage;
    const currentConditions = sortedConditions.slice(indexOfFirstCondition, indexOfLastCondition);

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Condition Management</h1>
                    </div>
                    <div className="flex space-x-2">
                        <form className="flex items-center" onSubmit={(e) => e.preventDefault()}>
                            <input
                                type="search"
                                id="condition-search"
                                className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:ring-primary-500 focus:border-primary-500"
                                placeholder="Search conditions"
                                onChange={handleSearchChange}
                            />
                        </form>
                        <button onClick={handleCreate} type="button" className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium">
                            Create New Condition
                        </button>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden">
                    <table className="min-w-full bg-white border border-gray-200 dark:border-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                                <th onClick={() => handleSort('name')} className="py-2 px-4 border-b text-left text-gray-700 dark:text-gray-300 cursor-pointer">Name</th>
                                <th className="py-2 px-4 border-b text-left text-gray-700 dark:text-gray-300">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentConditions.map((condition) => (
                                <tr key={condition.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                    <td className="py-2 px-4 border-b text-left">{condition.name}</td>
                                    <td className="py-2 px-4 border-b flex items-center space-x-2 text-left">
                                        <button
                                            onClick={() => handleEdit(condition.id)}
                                            className="bg-yellow-500 text-white px-4 py-1 rounded mr-2 flex items-center"
                                        >
                                            <HiPencil className="w-5 h-5 mr-1" />
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDelete(condition.id)}
                                            className="bg-red-500 text-white px-4 py-1 rounded flex items-center"
                                        >
                                            <HiTrash className="w-5 h-5 mr-1" />
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center items-center mt-4 p-6">
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        numItemsPerPage={itemsPerPage}
                        onNumItemsPerPageChange={handleNumItemsPerPageChange}
                    />
                </div>
            </div>
        </div>
    );
}

const CreateConditionPopUp = ({ onContinue, onClose }) => {
    const [newConditionName, setNewConditionName] = useState('');

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded">
                <h2 className="text-xl font-bold mb-4">Edit Condition</h2>
                <input
                    type="text"
                    className="border p-2 mb-4 w-full"
                    value={newConditionName}
                    onChange={(e) => setNewConditionName(e.target.value)}
                    placeholder="Enter new condition name"
                />
                <button
                    onClick={(e) => onContinue(e, newConditionName)}
                    className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                >
                    Save
                </button>
                <button
                    onClick={onClose}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default ConditionScripting;
