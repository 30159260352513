import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { useParams } from 'react-router-dom';

const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
  const [assessment, setAssessment] = useState({});
  const [scenario, setScenario] = useState(null);
  const { assessmentId } = useParams();

  useEffect(() => {
    console.log('AssessmentProvider rendered with assessmentId:', assessmentId);
    const fetchAssessmentData = async () => {
      const assessmentDocumentRef = doc(firestore, 'assessments', assessmentId);

      try {
        const assessmentSnapshot = await getDoc(assessmentDocumentRef);
        const assessmentData = {
          id: assessmentId,
          ...assessmentSnapshot.data()
        };
        console.log('Fetched assessment data:', assessmentData);
        setAssessment(assessmentData);
      } catch (error) {
        setAssessment({});
        console.error('Error fetching assessment data:', error);
      }
    };

    if (assessmentId) {
      fetchAssessmentData();
    }
  }, [assessmentId]);

  useEffect(() => {
    const fetchScenarioData = async () => {
      if (assessment && assessment.scenarioID) {
        try {
          const scenarioDocumentRef = doc(firestore, 'scenarios', assessment.scenarioID);
          const scenarioSnapshot = await getDoc(scenarioDocumentRef);
          const scenarioData = {
            id: assessment.scenarioID,
            ...scenarioSnapshot.data()
          };
          console.log('Fetched scenario data:', scenarioData);
          setScenario(scenarioData);
        } catch (error) {
          setScenario(null);
          console.error('Error fetching scenario data:', error);
        }
      }
    };

    fetchScenarioData();
  }, [assessment]);

  return (
    <AssessmentContext.Provider value={{ assessment, scenario }}>
      {children}
    </AssessmentContext.Provider>
  );
};


export const useAssessment = () => {
  return useContext(AssessmentContext);
};
