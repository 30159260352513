import React, { useEffect, useState } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import PDFViewer from '../../../components/PDFViewer'
import { useAssessment } from '../contexts/AssessmentContext';

const Prebriefing = ({ onContinue }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { assessment, scenario } = useAssessment();

    useEffect(() => {
        const url = assessment ? assessment.prebriefing ?? "invalid.pdf" : "invalid.pdf";
        if (url != "invalid.pdf") {
            const storage = getStorage();
            const pdfRef = ref(storage, url);

            getDownloadURL(pdfRef)
                .then((url) => {
                    console.log("PDF URL: " + url);
                    setPdfUrl(url);
                })
                .catch((error) => {
                    console.error('Error fetching PDF from Firebase Storage:', error);
                });
        }
    }, [scenario]); // Empty dependency array ensures the effect runs once on mount

    return (
        <section className={'bg-white dark:bg-gray-900'}>
            <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
                <div className="mt-4 md:mt-0">
                    <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                        Prebriefing
                    </h2>
                    <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
                        You can either read the prebriefing directly from the Portal here, or download the PDF using the button below.<br />Once you are ready to continue, you may press the "Continue" button.
                    </p>
                    <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <a href={pdfUrl} target="_blank" rel="noopener noreferrer" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                            Download PDF
                        </a>
                        <button onClick={onContinue} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                            Continue
                            <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                </div>
                <PDFViewer pdfUrl={pdfUrl} />
                <br></br>
                <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">Need more info?</h2>
                <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">Need extra explanation for this assessment? Watch the video below for a more in-depth explanation.</p>
                <iframe class="mx-auto w-full max-w-2xl h-64 rounded-lg sm:h-96" src={scenario?.prebriefingVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            
        </section>
    )
}

export default Prebriefing;