import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { coursesCollection, firestore } from '../../../firebase';
import { useParams } from 'react-router-dom';
import { fetchDataObject } from '../../../services/FirebaseService';

const GradingContext = createContext();

export const GradingProvider = ({ children, gradeId: propGradeId, optionalGrade }) => {
    const [grade, setGrade] = useState(null);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [scenario, setScenario] = useState(null);
    const [assessment, setAssessment] = useState(null);
    const [course, setCourse] = useState(null);
    const { gradeId: urlGradeId } = useParams();  // gradeId from the URL
    const gradeId = propGradeId || urlGradeId;    // Use the propGradeId if provided, otherwise fallback to URL gradeId

    useEffect(() => {
        const fetchGradeData = async () => {
            if (!gradeId) {
                console.error('Grade ID is not available');
                return;
            }
            const gradeDocumentRef = doc(firestore, 'grades', gradeId);

            try {
                const gradeSnapshot = await getDoc(gradeDocumentRef);
                if (gradeSnapshot.exists()) {
                    setGrade({
                        id: gradeId,
                        ...gradeSnapshot.data(),
                    });
                    console.log("Loaded grade: " + gradeId);
                } else {
                    console.error('Grade not found');
                }
            } catch (error) {
                setGrade(null);
                console.error('Error fetching grade data:', error);
            }
        };

        if (!optionalGrade) {
            fetchGradeData();
        } else {
            setGrade(optionalGrade);
        }
    }, [gradeId, optionalGrade]);  // Dependencies include gradeId and optionalGrade

    // Fetch the questionnaire, scenario, and course if a grade is loaded
    useEffect(() => {
        const fetchQuestionnaire = async () => {
            if (!grade) return;

            try {
                const assessmentRef = doc(firestore, "assessments", grade.assessmentID);
                const assessmentDoc = await getDoc(assessmentRef);
                if (!assessmentDoc.exists()) {
                    console.error('Assessment not found');
                    return;
                }

                setAssessment(assessmentDoc.data());

                const scenarioID = assessmentDoc.data().scenarioID;
                const scenarioRef = doc(firestore, "scenarios", scenarioID);
                const scenarioDoc = await getDoc(scenarioRef);
                if (!scenarioDoc.exists()) {
                    console.error('Scenario not found');
                    return;
                }

                setScenario(scenarioDoc.data());

                const chosenQuestionnaireId = scenarioDoc.data().chosenQuestionnaireId;
                const questionnaireRef = doc(firestore, "questionaires", chosenQuestionnaireId);
                const questionnaireDoc = await getDoc(questionnaireRef);
                if (!questionnaireDoc.exists()) {
                    console.error('Questionnaire not found');
                    return;
                }

                setQuestionnaire(questionnaireDoc.data());

                const c = await fetchDataObject(assessmentDoc.data().classroomId, coursesCollection);
                if (c) {
                    setCourse(c);
                } else {
                    console.error('Course not found');
                }


            } catch (error) {
                console.error('Error fetching assessment/questionnaire data:', error);
            }
        };

        fetchQuestionnaire();
    }, [grade]);


    return (
        <GradingContext.Provider value={{ grade, questionnaire, scenario, assessment, course }}>
            {children}
        </GradingContext.Provider>
    );
};

export const useGrade = () => {
    return useContext(GradingContext);
};
