import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getFileUrl } from '../../../services/FirebaseService';

const Materials = ({ handleUploadDocument, course, user, showNotification, handleDeleteMaterial }) => {
    return (
        <div className="md:col-span-1">
            <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
                <div className="flex justify-between items-center">
                    <h3 className="text-xl font-semibold dark:text-white">Learning Materials</h3>
                    {(user?.roleName === "Admin" || (user?.roleName !== "Student" && course?.id !== "66Fqzp90Cib8lmwCN3QZ")) && (
                        <div className='flex items-center'>
                            <button onClick={handleUploadDocument} className="p-2 text-white bg-blue-500 rounded-md hover:bg-blue-700">
                                Upload
                            </button>
                        </div>
                    )}
                </div>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700 mt-4">
                    {
                        course?.learningMaterials?.length > 0 ?
                            course?.learningMaterials?.map((material, index) => (
                                <li key={index} className="py-3 sm:py-4 flex justify-between items-center">
                                    <button onClick={async () => {
                                        const url = await getFileUrl(`learningMaterials/${course.id}/${material}`);
                                        if (url) {
                                            window.open(url, '_blank');
                                        } else {
                                            showNotification('danger', 'Error: Unable to open file');
                                        }
                                    }} target="_blank" rel="noopener noreferrer" className="text-sm font-medium text-blue-600 hover:underline">
                                        {material}
                                    </button>
                                    {(user?.roleName === "Admin" || (user?.roleName !== "Student" && course?.id !== "66Fqzp90Cib8lmwCN3QZ")) && (
                                        <button onClick={() => {
                                            if (window.confirm(`Are you sure you want to delete ${material}?`)) {
                                                handleDeleteMaterial(material);
                                            }
                                        }} className="text-red-500 hover:text-red-700">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    )}
                                </li>
                            )) :
                            (<tbody className="divide-y items-center flex justify-center divide-gray-200 dark:divide-gray-700">
                                <tr>
                                    <td className="w-full text-center mx-auto py-12" colSpan="5">
                                        <img className="w-32 h-32 mx-auto"
                                            src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                                            alt="image empty states" />
                                        <p className="text-gray-700 dark:text-white font-medium text-lg text-center">No learning materials.</p>
                                        <p className="text-gray-500 dark:text-gray-400 text-center">You can upload new learning materials for students to see.</p>
                                    </td>
                                </tr>
                            </tbody>)}
                </ul>
            </div>
        </div>
    );
};

export default Materials;
