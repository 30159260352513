import React, { useState } from 'react';
import { useNotification } from '../../../contexts/NotificationContext';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useAppData } from '../../../contexts/AppDataContext';

export default function CreateAssignment({ classroomId, onClose, onNavigateAfterCreate }) {

  const [assignmentName, setAssignmentName] = useState('');
  const [selectedScenario, setSelectedScenario] = useState('');

  const { scenarios, templates } = useAppData();

  const { showNotification } = useNotification();
  const { showPopUp } = usePopUp();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedScenario) {
      showNotification('danger', 'Please select a scenario!');
      return;
    }

    showNotification('success', 'Assignment Initialized!');
    onNavigateAfterCreate(selectedScenario, classroomId, assignmentName);
    showPopUp(null);
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-y-auto ${onClose ? 'flex' : 'hidden'} items-center justify-center`}>
      <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">Create Assignment</h3>
            <button onClick={onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto justify content-end dark:hover:bg-gray-600 dark:hover:text-white">
              <span className="sr-only">Close modal</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                id="assignmentName"
                value={assignmentName}
                onChange={(e) => setAssignmentName(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter assignment name"
                required
              />
            </div>
            <fieldset className="mb-4">
              <legend className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-2">Select Scenario</legend>
              <div className="max-h-64 overflow-y-auto">
                {scenarios.map((scenario, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      id={`scenario-${index}`}
                      type="radio"
                      name="scenario"
                      value={scenario.id}
                      checked={selectedScenario === scenario.id}
                      onChange={(e) => setSelectedScenario(e.target.value)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor={`scenario-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      {scenario.name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            {templates && templates.length > 0 && (
              <fieldset className="mb-4">
                <legend className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-2">or select one of your templates</legend>
                <div className="max-h-64 overflow-y-auto">
                  {templates.map((template, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <input
                        id={`template-${index}`}
                        type="radio"
                        name="scenario"
                        value={template.id}
                        checked={selectedScenario === template.id}
                        onChange={(e) => setSelectedScenario(e.target.value)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor={`template-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        {template.assignmentName}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            )}
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Start
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
