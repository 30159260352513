import React, { useEffect, useState } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import PDFViewer from '../../../components/PDFViewer'
import { useAssessment } from '../contexts/AssessmentContext';
import { updateDoc } from 'firebase/firestore';
import { useUser } from '../../../contexts/UserContext';

import { firestore } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useGrade } from '../../grades/contexts/GradingContext';
import { useAppData } from '../../../contexts/AppDataContext';
import { useNotification } from '../../../contexts/NotificationContext';

const Reflection = ({ onContinue }) => {
  const [answers, setAnswers] = useState([]);
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([]);
  const { scenario } = useAssessment();
  const navigate = useNavigate();

  const {showNotification} = useNotification();

  const { grade } = useGrade();
  const { grades, setGrades} = useAppData();

  const user = useUser();

  useEffect(() => {
    if (scenario && answers.length <= 0) {
      //Set all answers to unanswered to fill the array and prevent Firebase from bitching
      const emptyAnswers = Array(scenario.reflection?.length).fill("unanswered");
      setAnswers(emptyAnswers);
      console.log("Initialized answers array with length: " + scenario.reflection?.length)
    }
  }, [scenario]);

  const handleConfirm = async () => {
    //Upload the answers to grades

    const userDocRef = doc(firestore, 'users', user.id);
    const userDoc = await getDoc(userDocRef);
    const gradesArray = userDoc.data()?.grades || [];
    // Pak het laatste element van de grades array
    if (gradesArray.length > 0) {
      const lastGradeId = gradesArray[gradesArray.length - 1];
      // Gebruik de laatste gradeId om het grade document op te halen
      const gradeRef = doc(firestore, 'grades', lastGradeId);

      await updateDoc(gradeRef, {
        "reflection": answers
      });

      await updateDoc(userDocRef, {
        startedAssignment : ""
      });

      console.log("Updated reflection answers to grade doc: " + lastGradeId);
      let oldGrades = { ...grades };
      oldGrades[user.id] = [...oldGrades[user.id] ?? [], {...grade, id: lastGradeId}];
      setGrades(oldGrades);
    }

    navigate("/main/assesmentsoverview")
    showNotification('success', 'Assignment completed!');
  }

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  return (
    <section className={'bg-white dark:bg-gray-900'}>
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 sm:py-16 lg:px-6">
        <div className="mt-4 md:mt-0">
          <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Reflection
          </h2>
          <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Time to reflect on the simulation and your experience.
          </p>
        </div>
        {scenario?.reflection?.map((item, index) =>
          <div class="max-w-2xl mx-auto px-4">
            <div class="flex justify-between items-center mb-6">
              <h2 class="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">{item}</h2>
            </div>
            <form class="mb-6">
              <div class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <label for="comment" class="sr-only">Your comment</label>
                <textarea id="comment" rows="6" onChange={(e) => handleAnswerChange(index, e.target.value)}
                  class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                  placeholder="Your answer..." required></textarea>
              </div>
            </form>
          </div>)}
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <button onClick={handleConfirm} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
            Finish
          </button>
        </div>
      </div>
    </section>
  );

}

export default Reflection;