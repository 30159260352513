import React from 'react';
import Courseta_Logo from '../../../assets/images/Courseta_Logo.png';

const People = ({ attendees, user, handleAttendeeClick, handleAddPeopleToClassroom, course }) => {
    return (
        <div className="p-4 mt-5 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold whitespace-nowrap dark:text-white">Students</h3>
                {user?.roleName !== "Student" && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleAddPeopleToClassroom}
                    >
                        Edit
                    </button>
                )}
            </div>
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {attendees?.length > 1 ? (attendees?.map((attendee, index) => (
                    attendee.id !== user?.id ?
                        <li key={index} className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4 cursor-pointer" onClick={() => handleAttendeeClick(attendee.id)}>
                                <div className="flex-shrink-0">
                                    <img className="w-8 h-8 rounded-full" src={attendee.photo_url || Courseta_Logo} alt={`${attendee.firstName} {attendee.lastName} profile`} />
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {attendee.firstName} {attendee.lastName}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {attendee.email}
                                    </p>
                                </div>
                            </div>
                        </li> : <div></div>
                ))) :
                    (<tbody className="divide-y items-center flex justify-center divide-gray-200 dark:divide-gray-700">
                        <tr>
                            <td className="w-full text-center mx-auto py-12" colSpan="5">
                                <img className="w-32 h-32 mx-auto"
                                    src="https://res.cloudinary.com/daqsjyrgg/image/upload/v1690261234/di7tvpnzsesyo7vvsrq4.svg"
                                    alt="image empty states" />
                                <p className="text-gray-700 dark:text-white font-medium text-lg text-center">No students to display.</p>
                                <p className="text-gray-500 dark:text-gray-400 text-center">You can add new students to the course.</p>
                                {user?.roleName !== "Student" && (
                                    <button onClick={handleAddPeopleToClassroom}
                                        className="flex items-center px-4 py-1.5 border border-gray-400 mt-4 hover:text-white text-gray-600 dark:text-gray-300 rounded text-sm hover:bg-blue-600 dark:hover:bg-blue-700 mx-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                            stroke="currentColor" className="w-6 h-6  mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        Add New Student
                                    </button>
                                )}
                            </td>
                        </tr>
                    </tbody>)}
            </ul>
        </div>
    );
};

export default People;
