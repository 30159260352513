import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { usePopUp } from '../../../contexts/PopUpContext';
import StartSimPopUp from './StartSimPopUp';
import { getFileUrl } from '../../../services/FirebaseService';

const AssessmentCard = ({ assessment, canManage, onDelete }) => {
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();
    const onClose = () => showPopUp(null);
    const [imageUrl, setImageUrl] = useState(null);

    // Fetch the image from Firebase using the scenarioPicture URL
    useEffect(() => {
        const fetchImage = async () => {
            if (assessment.scenarioPicture) {
                const url = await getFileUrl(assessment.scenarioPicture); // Fetch the image URL from Firebase
                setImageUrl(url);
            }
        };
        fetchImage();
    }, [assessment.scenarioPicture]);

    const handleDeleteClick = () => {
        if (window.confirm(`Are you sure you want to delete "${assessment.assignmentName}"?`)) {
            onDelete(assessment.id);
        }
    };

    const handleStartSimulationClick = () => {
        showPopUp(<StartSimPopUp onClose={onClose} navigate={navigate} assessment={assessment} />);
    };

    const date = assessment.dueDate?.toDate();
    const formattedDateTime = date?.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        + ' ' + date?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return (
        <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full">
            {/* Scenario Image */}
            {imageUrl ? (
                <div className="w-full h-full"> {/* Increased height of the image to make the card longer */}
                    <img className="object-cover w-full h-full" src={imageUrl} alt={assessment.assignmentName} />
                </div>
            ) : (
                <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
                    <span>Loading Image...</span>
                </div>
            )}

            {/* Card content */}
            <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                    <div className="flex justify-between items-center mb-2">
                        <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                            {assessment.assignmentName}
                        </h4>
                        {canManage && (
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="text-red-500 cursor-pointer hover:text-red-600 text-lg md:text-xl"
                                title="Delete Assessment"
                                onClick={handleDeleteClick}
                            />
                        )}
                    </div>
                    <p className="text-gray-600 dark:text-gray-400 mb-4">Due: {formattedDateTime}</p>
                </div>

                {/* Start Simulation Button */}
                <button
                    onClick={handleStartSimulationClick}
                    type="button"
                    className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4"
                >
                    Start Now
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2 text-lg" />
                </button>
            </div>
        </div>
    );
};

export default AssessmentCard;
