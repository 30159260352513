import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 20,
    paddingHorizontal: 20,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2B6CB0',
  },
  logo: {
    width: 90,
    height: 60,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B6CB0',
    marginBottom: 10,
  },
  divider: {
    height: 3,
    backgroundColor: '#2B6CB0',
    marginTop: 10,
    marginBottom: 10,
  },
  overviewSection: {
    backgroundColor: '#EDF2F7',
    borderRadius: 8,
    padding: 10,
    marginBottom: 20,
  },
  overviewGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  overviewText: {
    fontSize: 12,
    color: '#4A5568',
  },
  overviewScore: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B6CB0',
  },
  gradeCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F7FAFC',
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
  },
  gradeCardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 15,
  },
  gradeCardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2D3748',
  },
  gradeCardDescription: {
    fontSize: 12,
    color: '#4A5568',
  },
  gradeCardScore: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2D3748',
    marginLeft: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    color: '#A0AEC0',
  },
  textBold: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#2B6CB0',
  },
  transcriptBubble: {
    marginBottom: 10,
    padding: 10,
    borderRadius: 8,
  },
  bubblePlayer: {
    backgroundColor: '#D3E0FF',
    alignSelf: 'flex-start',
  },
  bubblePatient: {
    backgroundColor: '#E2E8F0',
    alignSelf: 'flex-end',
  },
  overviewContainer: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: '#E5F0FF',
  },
  scoresTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 10,
    marginBottom: 10,
  },
  tableHeader: {
    backgroundColor: '#F7FAFC',
  },
  tableCell: {
    borderBottom: '1px solid #E2E8F0',
    padding: '10px',
    textAlign: 'left',
  },
  scoreCell: {
    textAlign: 'right',
  },
  totalScoreContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  totalScoreText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#2B6CB0',
  },
  congratulationText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

const Header = ({ logo, assessment, userData }) => (
    <View style={styles.header}>
      <Text style={styles.headerText}>
        Student: {`${userData?.firstName || 'Unknown'} ${userData?.lastName || 'Unknown'}`}
      </Text>
      <Text style={styles.headerText}>
        Assignment: {`${assessment?.assignmentName || 'Unknown Assignment'}`}
      </Text>
      <Text style={styles.headerText}>
        Course: {assessment?.course || 'Unknown Course'}
      </Text>
      <Text style={styles.headerText}>
        Submission Date: {new Date().toLocaleString()}
      </Text>
      <Image src={logo || 'fallback-logo-url.png'} style={styles.logo} />
    </View>
  );
// Main PDF Document Component
const PDFDocument = ({ userData, grade, course, assessment, categoryPercentages, totalResult, debriefing2Data, logo, scenario, questionnaire }) => {
  return (
    <Document>
      {/* Performance Overview */}
      <Page size="A4" style={styles.page}>
      <Header logo={logo} assessment={assessment} userData={userData} />
      <Text style={styles.sectionTitle}>PERFORMANCE OVERVIEW</Text>
      <View style={styles.divider}></View>
      <View style={styles.overviewGrid}>
        <View>
          <Text style={styles.textBold}>Student:</Text>
          <Text style={styles.text}>{userData?.firstName} {userData?.lastName}</Text>
        </View>
        <View>
          <Text style={styles.textBold}>Assignment:</Text>
          <Text style={styles.text}>{assessment?.assignmentName}</Text>
        </View>
        <View>
          <Text style={styles.textBold}>Course:</Text>
          <Text style={styles.text}>{course?.className}</Text>
        </View>
        <View>
          <Text style={styles.textBold}>Submission Date:</Text>
          <Text style={styles.text}>{grade?.creationDate || 'Unknown Date'}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Scores</Text>
        <View style={styles.divider}></View>
        <View style={styles.gradeCard}>
          <Text>Communication: {categoryPercentages?.Communication || 0}%</Text>
          <Text>Accuracy: {categoryPercentages?.Accuracy || 0}%</Text>
          <Text>Safety: {categoryPercentages?.Safety || 0}%</Text>
          <Text>Answers: {categoryPercentages?.Answers || 0}%</Text>
        </View>
        <Text style={styles.gradeCardScore}>Total Score: {totalResult}%</Text>
      </View>

      <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
    </Page>

      {/* Accuracy Performance */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>ACCURACY PERFORMANCE</Text>
        <View style={styles.divider}></View>
        {Object.entries(grade?.Accuracy || {}).map(([key, value], index) => {
          const [title, description] = key.split('/').map((text) => text.trim());
          const [achievedScore, maxScore] = value.split('/').map(Number);
          return (
            <View key={index} style={styles.gradeCard}>
              <View style={styles.gradeCardContent}>
                <Text style={styles.gradeCardTitle}>{title}</Text>
                <Text style={styles.gradeCardDescription}>{description}</Text>
              </View>
              <Text style={styles.gradeCardScore}>{`${achievedScore} / ${maxScore}`}</Text>
            </View>
          );
        })}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>

      {/* Communication Performance */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>COMMUNICATION PERFORMANCE</Text>
        <View style={styles.divider}></View>
        {Object.entries(grade?.Communication || {}).map(([key, value], index) => {
          const [title, description] = key.split('/').map((text) => text.trim());
          const [achievedScore, maxScore] = value.split('/').map(Number);
          return (
            <View key={index} style={styles.gradeCard}>
              <View style={styles.gradeCardContent}>
                <Text style={styles.gradeCardTitle}>{title}</Text>
                <Text style={styles.gradeCardDescription}>{description}</Text>
              </View>
              <Text style={styles.gradeCardScore}>{`${achievedScore} / ${maxScore}`}</Text>
            </View>
          );
        })}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>

      {/* Safety Performance */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>SAFETY PERFORMANCE</Text>
        <View style={styles.divider}></View>
        {Object.entries(grade?.Safety || {}).map(([key, value], index) => {
          const [title, description] = key.split('/').map((text) => text.trim());
          const [achievedScore, maxScore] = value.split('/').map(Number);
          return (
            <View key={index} style={styles.gradeCard}>
              <View style={styles.gradeCardContent}>
                <Text style={styles.gradeCardTitle}>{title}</Text>
                <Text style={styles.gradeCardDescription}>{description}</Text>
              </View>
              <Text style={styles.gradeCardScore}>{`${achievedScore} / ${maxScore}`}</Text>
            </View>
          );
        })}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>

      {/* Answers Accuracy */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>ANSWERS ACCURACY</Text>
        <View style={styles.divider}></View>
        <View>
          {questionnaire?.questions?.map((question, index) => {
            const answerIndicesString = grade?.Answers?.[String(index)] || '';
            const answerIndices = answerIndicesString.split(';').map(Number).filter(index => !isNaN(index));
            const hasAnswered = answerIndices.length > 0;
            const selectedChoices = hasAnswered
              ? answerIndices.map(answerIndex => question.answers[answerIndex]?.answer).filter(Boolean).join(' AND ')
              : 'No answer given';
            const correctAnswersCount = answerIndices.reduce((count, answerIndex) => {
              const answer = question.answers[answerIndex];
              return count + (answer && answer.isCorrect ? 1 : 0);
            }, 0);
            return (
              <View key={index}>
                <Text style={styles.textBold}>{question.questionTitle}</Text>
                <Text>{selectedChoices}</Text>
                <Text>{`${correctAnswersCount}/${answerIndices.length} Points Earned`}</Text>
              </View>
            );
          })}
        </View>
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>

      {/* Clinical Judgement Debriefing */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>CLINICAL JUDGEMENT DEBRIEFING</Text>
        <View style={styles.divider}></View>
        {debriefing2Data?.map((data, index) => (
          <View key={index}>
            <Text style={styles.textBold}>{`Question: ${data.question}`}</Text>
            {data?.answers?.map((choice, i) => (
              <View
                key={i}
                style={{
                  backgroundColor: data.userAnswer === i ? '#D3E0FF' : '#E2E8F0',
                  padding: 5,
                  borderRadius: 4,
                  marginBottom: 5,
                }}
              >
                <Text>{choice}</Text>
              </View>
            ))}
            <Text style={styles.textBold}>{data.userAnswer === data.correctAnswer ? 'Correct!' : 'Incorrect!'}</Text>
            {data.rationale && <Text>Rationale: {data.rationale}</Text>}
          </View>
        ))}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>

      {/* Simulation Transcript */}
      <Page size="A4" style={styles.page}>
        <Text style={styles.sectionTitle}>SIMULATION TRANSCRIPT</Text>
        <View style={styles.divider}></View>
        {grade?.transcript?.map((line, index) => {
          const [speaker, time, message] = line.split('/');
          const isPlayer = speaker === 'Player';
          return (
            <View
              key={index}
              style={[
                styles.transcriptBubble,
                isPlayer ? styles.bubblePlayer : styles.bubblePatient,
              ]}
            >
              <Text style={styles.textBold}>{isPlayer ? 'Player' : 'Patient'}</Text>
              <Text>{message}</Text>
              <Text>{time}</Text>
            </View>
          );
        })}
        <Text style={styles.footer} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} fixed />
      </Page>
    </Document>
  );
};

export { PDFDocument };
