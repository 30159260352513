import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/22.png';
import { useNotification } from '../../contexts/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore, storage } from '../../firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useUser } from './../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import defaultImage from '../../assets/images/22.png';

const getClassRoomById = async (classId) => {
    const classRoomRef = doc(firestore, 'classRooms', classId);
    const classRoomDoc = await getDoc(classRoomRef);
    if (classRoomDoc.exists()) {
        return { id: classRoomDoc.id, ...classRoomDoc.data() };
    } else {
        console.error('Classroom not found:', classId);
        return null;
    }
};

const getGradeById = async (gradeId) => {
    const gradeRef = doc(firestore, 'grades', gradeId);
    const gradeDoc = await getDoc(gradeRef);
    if (gradeDoc.exists()) {
        return { id: gradeDoc.id, ...gradeDoc.data() };
    } else {
        console.error('Grade not found:', gradeId);
        return null;
    }
};

export default function UserProfile() {
    const user = useUser();
    const { showNotification } = useNotification();
    const { grades, getUserData, assignments, deleteGrade, isLoading } = useAppData();
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const [classRooms, setClassRooms] = useState([]);
    const [userGrades, setUserGrades] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);

    const handleProfilePictureUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `profileImages/${user.id}`);
        try {
            await uploadBytes(storageRef, file);
            const photoURL = await getDownloadURL(storageRef);
            await updateDoc(doc(firestore, 'users', user.id), { photo_url: photoURL });
            showNotification('success', 'Profile picture uploaded successfully.');
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            showNotification('danger', 'Failed to upload profile picture.');
        }
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            showNotification('danger', 'New passwords do not match.');
            return;
        }

        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
        );

        try {
            await reauthenticateWithCredential(user, credential);
            await updatePassword(user, newPassword);
            showNotification('success', 'Password updated successfully!');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            showNotification('danger', 'Failed to update password. ' + error.message);
            console.error('Error updating password:', error);
        }
    };

    const handleGradeClick = (gradeId) => {
        navigate(`/main/gradingdetails/${gradeId}`);
    };

    const handleDeleteGrade = async (gradeId, userId) => {
        try {
            await deleteGrade(gradeId, userId);
            showNotification('success', 'The grade was removed!');
            setUserGrades(userGrades.filter(grade => grade.id !== gradeId));
        } catch (e) {
            showNotification('danger', 'Something went wrong');
        }
    };

    useEffect(() => {
        if (user?.attendingClasses) {
            Promise.all(user.attendingClasses.map(classId => getClassRoomById(classId)))
                .then(classes => setClassRooms(classes))
                .catch(error => {
                    console.error('Error fetching class names:', error);
                    showNotification('danger', 'Failed to fetch class names');
                });
        }
    }, [user?.attendingClasses, showNotification]);

    useEffect(() => {
        if (user?.grades) {
            Promise.all(user.grades.map(gradeId => getGradeById(gradeId)))
                .then(gradesData => setUserGrades(gradesData))
                .catch(error => {
                    console.error('Error fetching grades:', error);
                    showNotification('danger', 'Failed to fetch grades');
                });
        }
    }, [user?.grades, showNotification]);

    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-6 mb-6">
            <div className="flex items-center mb-6">
                <img className="w-24 h-24 rounded-full object-cover" src={user?.photo_url || logo} alt="Profile" />
                <div className="ml-4">
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white">{user?.firstName + " " + user?.lastName ?? ''}</h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Role: {user?.roleName ?? ''}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Joined Date: {user?.datejoined ?? 'N/A'}</p>
                    <div className="flex mt-2">
                        <button
                            type="button"
                            className="mr-2 px-3 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-primary-800"
                            onClick={() => document.getElementById('upload-profile-picture').click()}
                        >
                            Change Profile Picture
                        </button>
                        <input
                            type="file"
                            onChange={handleProfilePictureUpload}
                            className="hidden"
                            id="upload-profile-picture"
                            accept="image/*"
                        />
                        <button className="px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white">Remove</button>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                <div className="lg:col-span-2">
                    <div className="p-6 mb-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Details</h3>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email</label>
                                <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.email}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Institute</label>
                                <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.schoolName}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Name</label>
                                <p className="mt-1 text-sm text-gray-900 dark:text-white">{user?.firstName + " " + user?.lastName}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="p-6 mb-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Privacy & Security</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center justify-between col-span-2">
                                <label htmlFor="two-step-verification" className="text-sm font-medium text-gray-900 dark:text-white">Allow Two Step Verification</label>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" id="two-step-verification" className="sr-only peer" checked={isMFAEnabled} onChange={() => setIsMFAEnabled(!isMFAEnabled)} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="p-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Notification Settings</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-900 dark:text-white">Email Notifications</label>
                                <div className="mt-2 space-y-2">
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Assignment Submission</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Late Submissions</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Overdue Assignments</label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-900 dark:text-white">Phone Notifications</label>
                                <div className="mt-2 space-y-2">
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Assignment Submission</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Late Submissions</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" className="text-primary-600 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:bg-gray-700" />
                                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-white">Overdue Assignments</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="p-6 mb-6">
    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Recent Grades</h3>
    {userGrades.length === 0 ? ( // Check if there are no grades
        <p className="text-sm text-gray-500 dark:text-gray-400">No recent grades available.</p>
    ) : (
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {userGrades.map((grade, index) => {
                const assignmentName = assignments.find((assignment) => assignment.id === grade.assessmentID)?.assignmentName || 'Unknown assignment';
                const averageResult = grade.averageResult || 'N/A';
                const resultBadgeStyle = (averageResult) => {
                    if (averageResult === 'N/A') {
                        return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
                    }
                    const result = parseFloat(averageResult);
                    if (result >= 0 && result < 50) {
                        return 'bg-red-500 dark:bg-red-700 text-white';
                    } else if (result >= 50 && result < 70) {
                        return 'bg-yellow-500 dark:bg-yellow-700 text-white';
                    } else if (result >= 70 && result <= 100) {
                        return 'bg-green-500 dark:bg-green-700 text-white';
                    }
                    return 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-300';
                };

                return (
                    <li key={index} className="py-4 flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0">
                                <svg className="w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V5z" clipRule="evenodd" />
                                    <path d="M4 9h12v2H4z" />
                                </svg>
                            </div>
                            <div className="flex-1 min-w-0">
                                <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{assignmentName}</span>
                                <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{averageResult}</span>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => handleGradeClick(grade.id)}
                                className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
                            >
                                View
                            </button>
                        </div>
                    </li>
                );
            })}
        </ul>
    )}
</div>

<div className="p-6">
    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Enrolled Courses</h3>
    {classRooms.length === 0 ? ( // Check if there are no courses
        <p className="text-sm text-gray-500 dark:text-gray-400">No enrolled courses available.</p>
    ) : (
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {classRooms.map((classRoom, index) => (
                <li key={index} className="py-4">
                    <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                            <svg className="w-5 h-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M4 2a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V2z" />
                                <path fillRule="evenodd" d="M3 5a1 1 0 011 1v11a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 112 0v11a3 3 0 01-3 3H5a3 3 0 01-3-3V6a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="flex-1 min-w-0">
                            <span className="block text-base font-semibold text-gray-900 truncate dark:text-white">{classRoom.className}</span>
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(`/main/classroomdetails/${classRoom?.id}`);
                            }}
                            className="inline-flex items-center rounded-lg p-2 text-xs font-medium uppercase text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm"
                        >
                            View Course
                            <svg
                                className="ml-1 h-4 w-4 sm:h-5 sm:w-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    )}
</div>
</div>

                <div className="lg:col-span-1">
                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 p-6 mb-6">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Reset Password</h3>
                        <form onSubmit={handlePasswordChange}>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <label htmlFor="current-password" className="block text-sm font-medium text-gray-900 dark:text-white">Current Password</label>
                                    <input type="password" id="current-password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                </div>
                                <div>
                                    <label htmlFor="new-password" className="block text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                    <input type="password" id="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                </div>
                                <div>
                                    <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                    <input type="password" id="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="••••••••" required />
                                </div>
                                <div>
                                    <button type="submit" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-700 dark:hover:bg-primary-800 dark:focus:ring-primary-800">Confirm New Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
