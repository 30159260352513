import React, { useEffect } from 'react';
import { Datepicker } from 'flowbite-react';
import { useScenario } from '../contexts/ScenarioContext';

const DueDate = () => {
  const { scenario, setField } = useScenario();

  useEffect(() => {}, [scenario]);

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Due date</h2>
      <div className="flex flex-col gap-4 p-4">
        <Datepicker
          id="dueDate"
          onSelectedDateChanged={(date) => {
            setField(date, 'dueDate');
          }}
          className="mb-2"
          dateFormat="yyyy-MM-dd"
        />
      </div>
    </div>
  );
};

export default DueDate;
