import { useEffect, useState } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useAppData } from '../../contexts/AppDataContext';
import { useUser } from '../../contexts/UserContext';
import { usePopUp } from '../../contexts/PopUpContext';

export default function AddPeopleToClassroom({ course, onClose, addUsers, removeUsers }) {
  const { showNotification } = useNotification();
  const { showPopUp } = usePopUp();

  const { school } = useAppData();
  const [initSelectedUsers, setInitSelectedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { getUserData } = useAppData();
  const user = useUser();

  useEffect(() => {
    if (course) {
      setInitSelectedUsers(course.attendees?.filter(attendee => attendee !== user));
      setSelectedUsers(course.attendees?.filter(attendee => attendee !== user));
    }
  }, [course]);

  const handleCheckboxChange = (user) => {
    setSelectedUsers((prevSelectedUsers) =>
      selectedUsers.find((n) => n === user.id)
        ? prevSelectedUsers.filter((n) => n !== user.id)
        : [...prevSelectedUsers, user.id]
    );
  };

  const handleSaveChanges = async () => {
    showNotification('success', 'Attendees updated successfully!');
    onClose();

    const newAttendees = selectedUsers.filter(user => !initSelectedUsers.some(initUser => initUser === user));
    if (newAttendees.length > 0) {
      await addUsers(newAttendees);
    }

    const removedAttendees = initSelectedUsers.filter(user => !selectedUsers.some(initUser => initUser === user));
    if (removedAttendees.length > 0) {
      await removeUsers(removedAttendees);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-4 w-full max-w-2xl h-auto bg-white rounded-lg shadow dark:bg-gray-800">
        <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Select Course Attendees
          </h3>
          <button onClick={() => showPopUp(null)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto dark:hover:bg-gray-600 dark:hover:text-white">
            <span className="sr-only">Close modal</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <form onSubmit={(e) => e.preventDefault()} className="overflow-y-auto max-h-96">
          {
            school.attendees?.map((attendeeId) => {
              const attendee = getUserData(attendeeId);
              return (
                attendee?.id !== user.id &&
                <div key={attendee?.id} className="flex items-center mb-4">
                  <input
                    id={`checkbox-${attendee?.id}`}
                    type="checkbox"
                    checked={selectedUsers.find((n) => attendee?.id === n)}
                    onChange={() => handleCheckboxChange(attendee)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor={`checkbox-${attendee?.id}`}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {attendee?.firstName} {attendee?.lastName} - {attendee?.email}
                  </label>
                </div>
              );
            })
          }
          <button
            onClick={handleSaveChanges}
            className="mt-4 p-2 text-white bg-blue-500 rounded-md hover:bg-blue-700"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
}
