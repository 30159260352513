import React, { useState, useEffect } from 'react';
import { useScenario } from '../pages/scenarios/contexts/ScenarioContext';

export const getEnumKeyByValue = (enumObj, value) => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
};

const EnumCheckboxes = ({ enumObj, label, path }) => {
    const { scenario, setField } = useScenario();
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        if (scenario && scenario[path]) {
            const valuesArray = scenario[path].split(';').map(Number);
            setSelectedValues(valuesArray);
        }
    }, [scenario, path]);

    const handleCheckboxChange = (value) => {
        setSelectedValues((prevValues) => {
            const updatedValues = prevValues.includes(value)
                ? prevValues.filter((val) => val !== value)
                : [...prevValues, value];
            const updatedString = updatedValues.join(';');
            setField(updatedString, path); // Waarden opslaan als string
            console.log(updatedString);
            return updatedValues;
        });
    };

    return (
        <div className="container my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800 w-md">
            <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                {label}
            </label>
            {Object.keys(enumObj).map((key) => (
                <div key={key} className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        id={key}
                        name={key}
                        value={enumObj[key]}
                        checked={selectedValues.includes(enumObj[key])}
                        onChange={() => handleCheckboxChange(enumObj[key])}
                        className="mr-2 rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:checked:bg-blue-600 dark:checked:border-blue-600"
                    />
                    <label htmlFor={key} className="text-gray-700 dark:text-gray-300">
                        {key}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default EnumCheckboxes;
