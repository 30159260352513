import logo from '../../assets/images/Courseta_Logo.png';
import React, { useRef, useState, useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { updatePassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../../firebase';
import { doc, setDoc, getDoc, deleteDoc, arrayUnion, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

export default function Registration() {
  const { register } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({ token: null });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setUserData({ ...userData, token: token });
      fetchUserData(token);
    }
  }, [location.search]); // Depends on your router setup; might need window.location instead

  const fetchUserData = async (token) => {
    try {
      const docRef = doc(firestore, 'pendingRegistrations', token);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserData({ ...docSnap.data(), token }); // Update the state with fetched data
        emailRef.current.value = docSnap.data().email; // Pre-fill the email
      } else {
        setError('Invalid or expired invitation link.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('An error occurred while fetching user data.');
    }
  };

  function formatDate(date) {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Maanden zijn van 0-11 in JavaScript
    const year = d.getFullYear();

    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }

    return `${day}/${month}/${year}`;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password) {
      setError('Email or password is missing.');
      setLoading(false);
      return;
    }

    const { token } = userData;

    if (!token) {
      setError('No registration token provided.');
      setLoading(false);
      return;
    }

    try {
      const docRef = doc(firestore, 'pendingRegistrations', token);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error('No pending registration found for this token.');
      }

      const userDetails = docSnap.data();

      const userCredential = await register(email, password);

      await setDoc(doc(firestore, 'users', userCredential.user.uid), {
        ...userDetails,
        id: userCredential.user.uid,
        datejoined: formatDate(Date.now())
      });

      await updateDoc(doc(firestore, 'schools', userDetails.schoolID), {
        attendees: arrayUnion(userCredential.user.uid)
      });

      await deleteDoc(docRef);

      window.localStorage.removeItem('emailForSignIn');

      showNotification('success', 'Registration completed successfully!');
      navigate('/#/dashboard');
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Registration failed. Please try again.');
      showNotification('danger', 'Error during registration.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="flex w-full h-screen max-w-none mx-auto bg-white dark:bg-gray-800">
        <div className="w-1/2 h-full flex items-center justify-center p-8">
          <div className="w-full max-w-md space-y-4 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Finish Registration
            </h1>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong className="font-bold">Error! </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input ref={emailRef} type="email" id="email" autoComplete="current-email" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input ref={passwordRef} type="password" id="password" autoComplete="current-password" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="••••••••" />
              </div>

              <button type="submit" disabled={loading} className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {loading ? 'Processing...' : 'Finish Registration'}
              </button>
            </form>
          </div>
        </div>
        <div className="w-1/2 h-full flex items-center justify-center bg-blue-600 rounded-tl-3xl rounded-bl-3xl">
          <img className="w-80 h-90" src={logo} alt="Courseta Logo" />
        </div>
      </div>
    </section>
  )
}
