import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '../pages/authentication/SignIn';
import NotFound from '../pages/fallbacks/404/NotFound';
import PrivateRoute from './PrivateRoutes';
import ProtectedRoute from './ProtectedRoute';
import UserProfile from '../pages/user_profile/UserProfile';
import UsersOverview from '../pages/overviews/UsersOverview';
import Dashboard from '../pages/dashboards/Dashboard';
import SchoolsOverview from '../pages/overviews/SchoolsOverview';
import TokenOverview from '../pages/overviews/TokenOverview';
import ScenariosOverview from '../pages/overviews/ScenariosOverview';
import QuestionairesOverview from '../pages/overviews/QuestionairesOverview';
import AssesmentsOverview from '../pages/assesments/AssesmentsOverview';
import Registration from '../pages/authentication/Registration';
import SchoolDetails from '../pages/details/SchoolDetails';
import GradesOverview from '../pages/overviews/GradesOverview';
import SimulationDetails from '../pages/assesments/SimulationDetails';
import { AssessmentProvider } from '../pages/assesments/contexts/AssessmentContext';
import Layout from '../pages/Layout';
import QuestionnaireCreator from '../pages/creators/QuestionaireCreator';
import ScenarioDetails from '../pages/scenarios/ScenarioDetails';
import { GradingProvider } from '../pages/grades/contexts/GradingContext';
import GradingDetails from '../pages/grades/GradingDetails';
import ClassRoomOverview from '../pages/courses/ClassRoomOverview';
import ClassRoomDetails from '../pages/courses/ClassRoomDetails';
import { CourseProvider } from '../pages/courses/contexts/CourseContext';
import TokenDetails from '../pages/details/TokenDetails';
import AssessmentDetails from '../pages/assesments/AssessmentDetails';
import ForgotPassword from '../pages/authentication/ForgotPassword';
import LibraryOverview from '../pages/overviews/LibraryOverview';
import CreateAssignment from '../pages/assesments/components/CreateAssignment';
import { ScenarioProvider } from '../pages/scenarios/contexts/ScenarioContext';
import VisualScripting from '../pages/scripting/VisualScripting';
import { ReactFlowProvider } from 'reactflow';
import { ScriptingProvider } from '../pages/scripting/contexts/ScriptingContext';
import MedicationScripting from '../pages/scripting/MedicationScripting';
import ConditionScripting from '../pages/scripting/ConditionScripting';
import GradeScripting from '../pages/scripting/GradesScriptings';
import { useUser } from '../contexts/UserContext';


const AppRouter = () => {
  const user = useUser();

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/signin" />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="register" element={<Registration />} />

        {/* Gebruikers moeten ingelogd zijn voor alle routes binnen Layout */}
        <Route 
          path="/main" 
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
         

          {/* Public routes binnen de beveiligde layout */}
          <Route index element={<Navigate replace to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="simulationDetails/:assessmentId" element={
            <AssessmentProvider>
              <SimulationDetails />
            </AssessmentProvider>} 
          />
          <Route path="assessmentDetails/:assessmentId" element={
            <AssessmentProvider>
              <AssessmentDetails />
            </AssessmentProvider>} 
          />
          <Route path="assesmentsoverview" element={<AssesmentsOverview />} />
          <Route path="libraryoverview" element={<LibraryOverview />} />
          <Route path="gradesoverview" element={<GradesOverview />} />
          <Route path="gradingdetails/:gradeId" element={
            <GradingProvider>
              <GradingDetails />
            </GradingProvider>} 
          />
          <Route path="classroomoverview" element={<ClassRoomOverview />} />
          <Route path="classroomDetails/:classroomId" element={
            <CourseProvider>
              <AssessmentProvider>
                <ClassRoomDetails />
              </AssessmentProvider>
            </CourseProvider>} 
          />

          {/* Role based routes */}
          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="tokensoverview" element={<TokenOverview />} />
            <Route path="tokendetails/:tokenId" element={<TokenDetails />} />
          </Route>

          <Route element={<PrivateRoute roles={['Admin', 'Teacher']} />} >
            <Route path="users" element={<UsersOverview />} />
          </Route>

          <Route element={<PrivateRoute roles={['Admin', 'Teacher']} />} >
            <Route path="scenariosoverview" element={<ScenariosOverview />} />
            <Route path="scenarioDetails/:scenarioId" element={
              <ScenarioProvider>
                <ScenarioDetails />
              </ScenarioProvider>} 
            />
            <Route path="scenarioDetails/:scenarioId/visualScripting" element={
              <ScenarioProvider>
                <ReactFlowProvider>
                  <ScriptingProvider>
                    <VisualScripting />
                  </ScriptingProvider>
                </ReactFlowProvider>
              </ScenarioProvider>} 
            />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="medicationsoverview" element={<MedicationScripting />} />
            <Route path="medicationsoverview/:medicationId/visualScripting" element={
              <ScenarioProvider>
                <ReactFlowProvider>
                  <ScriptingProvider>
                    <VisualScripting />
                  </ScriptingProvider>
                </ReactFlowProvider>
              </ScenarioProvider>} 
            />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="conditionsoverview" element={<ConditionScripting />} />
            <Route path="conditionsoverview/:conditionId/visualScripting" element={
              <ScenarioProvider>
                <ReactFlowProvider>
                  <ScriptingProvider>
                    <VisualScripting />
                  </ScriptingProvider>
                </ReactFlowProvider>
              </ScenarioProvider>} 
            />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="gradesscriptoverview" element={<GradeScripting />} />
            <Route path="gradesscriptoverview/:gradesscriptId/visualScripting" element={
              <ScenarioProvider>
                <ReactFlowProvider>
                  <ScriptingProvider>
                    <VisualScripting />
                  </ScriptingProvider>
                </ReactFlowProvider>
              </ScenarioProvider>} 
            />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="questionnairesoverview" element={<QuestionairesOverview />} />
            <Route path="questionaires-creator/:id" element={<QuestionnaireCreator />} />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="createassignment" element={<CreateAssignment />} />
          </Route>

          <Route element={<PrivateRoute roles={['Admin']} />} >
            <Route path="schoolsoverview" element={<SchoolsOverview />} />
            <Route path="schoolDetails/:schoolId" element={<SchoolDetails />} />
          </Route>
        </Route>
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
};

export default AppRouter;
