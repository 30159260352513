/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { usePopUp } from "../contexts/PopUpContext";
import { useSettings } from "../contexts/SettingsContext";
import HelpPopUp from "../pages/help/Help_Popup";
import logout from "../assets/icons/logout.svg"
import calender from "../assets/icons/calendar.svg";
import grades from "../assets/icons/grades.svg";
import library from "../assets/icons/library.svg";
import question from "../assets/icons/question_mark.svg";
import { useAuth } from "../contexts/AuthContext";

const Sidebar = ({ items }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { showPopUp } = usePopUp();
    const { toggleDarkMode, darkMode } = useSettings();
    const [authenticatedItems, setAuthenticatedItems] = useState([]);
    const user = useUser();
    const {logout} = useAuth();

    useEffect(() => {
        if (user) {
            const filteredItems = items.filter((item) => !item.roles || item.roles.includes(user.roleName));
            setAuthenticatedItems(filteredItems);
        }
    }, [user]);

    const handleHelpClick = () => {
        showPopUp(<HelpPopUp onClose={() => showPopUp(null)} />);
    };

    const handleLogout = async () => {
        await logout();
        navigate("/signin");
    };

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <aside className="fixed top-0 left-0 w-64 h-screen pt-20 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 z-10">
            <div className="overflow-y-auto py-5 px-3 h-full">
                <ul className="space-y-4">
                    {authenticatedItems && authenticatedItems.map((item, index) => (
                        <li key={index}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (item.path) {
                                        navigate(item.path);
                                    } else if (item.onClick) {
                                        item.onClick();
                                    }
                                }}
                                className={`flex items-center p-3 text-base font-medium rounded-lg group ${isActive(item.path) ? 'bg-blue-600 text-white' : 'text-gray-700 dark:text-gray-300 hover:bg-blue-500 hover:text-white'}`}
                            >
                                <item.icon className={`w-6 h-6 ${isActive(item.path) ? 'text-white' : 'text-gray-500 dark:text-gray-400 group-hover:text-white'}`} />
                                <span className="ml-4">{item.label}</span>
                            </a>
                        </li>
                    ))}
                </ul>
                <ul className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleHelpClick();
                            }}
                            className="flex items-center p-3 text-base font-medium text-gray-700 rounded-lg dark:text-gray-300 hover:bg-blue-500 hover:text-white group"
                        >
                            <svg fill="#000000" version="1.1" width="24px" height="24px" viewBox="0 0 395.001 395" xmlSpace="preserve">
                            <g>
                                <g>
                                <path d="M322.852,0H72.15C32.366,0,0,32.367,0,72.15v250.7C0,362.634,32.367,395,72.15,395h250.701 c39.784,0,72.15-32.366,72.15-72.15V72.15C395.002,32.367,362.635,0,322.852,0z M370.002,322.85 c0,25.999-21.151,47.15-47.15,47.15H72.15C46.151,370,25,348.849,25,322.85V72.15C25,46.151,46.151,25,72.15,25h250.701 c25.999,0,47.15,21.151,47.15,47.15L370.002,322.85L370.002,322.85z" />
                                <path d="M197.501,79.908c-33.775,0-61.253,27.479-61.253,61.254c0,6.903,5.596,12.5,12.5,12.5c6.904,0,12.5-5.597,12.5-12.5 c0-19.99,16.263-36.254,36.253-36.254s36.253,16.264,36.253,36.254c0,11.497-8.314,19.183-22.01,30.474 c-12.536,10.334-26.743,22.048-26.743,40.67v40.104c0,6.902,5.597,12.5,12.5,12.5c6.903,0,12.5-5.598,12.5-12.5v-40.104 c0-6.832,8.179-13.574,17.646-21.381c13.859-11.426,31.106-25.646,31.106-49.763C258.754,107.386,231.275,79.908,197.501,79.908z" stroke="#475467" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M197.501,283.024c-8.842,0-16.034,7.193-16.034,16.035c0,8.84,7.192,16.033,16.034,16.033 c8.841,0,16.034-7.193,16.034-16.033C213.535,290.217,206.342,283.024,197.501,283.024z" />
                                </g>
                            </g>
                            </svg>



                            <span className="ml-4">Help</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleLogout();
                            }}
                            className="flex items-center p-3 text-base font-medium text-gray-700 rounded-lg dark:text-gray-300 hover:bg-blue-500 hover:text-white group"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 17L21 12M21 12L16 7M21 12H9M9 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span className="ml-4">Logout</span>
                        </a>
                    </li>
                </ul>
              
                 
                
            </div>
            <div className="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
                <button
                    onClick={toggleDarkMode}
                    id="theme-toggle"
                    type="button"
                    className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                >
                    <svg
                        id="theme-toggle-dark-icon"
                        className={darkMode ? "w-5 h-5" : "hidden w-5 h-5"}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                    </svg>
                    <svg
                        id="theme-toggle-light-icon"
                        className={darkMode ? "hidden w-5 h-5" : "w-5 h-5"}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                        ></path>
                    </svg>
                </button>
            </div>
        </aside>
    );
};

export default Sidebar;
