import React, { useState, useEffect } from 'react';
import { useNotification } from '../../../contexts/NotificationContext';
import { usePopUp } from '../../../contexts/PopUpContext';
import { useAppData } from '../../../contexts/AppDataContext';

const ScenarioFilter = ({ course, addScenarios, removeScenarios }) => {
    const { school, scenarios } = useAppData();
    const [initSelectedScenarios, setInitSelectedScenarios] = useState([]);
    const [selectedScenarios, setSelectedScenarios] = useState([]);

    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();

    useEffect(() => {
        if (course) {
            setInitSelectedScenarios(course?.availableScenarios ?? []);
            setSelectedScenarios(course?.availableScenarios ?? []);
        }
    }, [course]);

    const handleCheckboxChange = (scenarioId) => {
        setSelectedScenarios((prevSelectedScenarios) =>
            selectedScenarios.includes(scenarioId)
                ? prevSelectedScenarios.filter((n) => n !== scenarioId)
                : [...prevSelectedScenarios, scenarioId]
        );
    };

    const handleSaveChanges = async () => {
        showNotification('success', 'Open lab updated successfully!');
        showPopUp(null);

        const newScenarios = selectedScenarios.filter(scenario => !initSelectedScenarios.includes(scenario));
        const removedScenarios = initSelectedScenarios.filter(scenario => !selectedScenarios.includes(scenario));

        if (newScenarios.length > 0) {
            await addScenarios(newScenarios);
        }

        if (removedScenarios.length > 0) {
            await removeScenarios(removedScenarios);
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
            <div className="flex items-center justify-center min-h-screen relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 w-full max-w-2xl h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-800">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Select Available Open Lab
                        </h3>
                        <button onClick={() => showPopUp(null)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="max-h-64 overflow-y-auto">
                            {school?.availableScenarios?.map((scenarioId) => {
                                const scenario = scenarios.find(s => s.id === scenarioId);
                                const scenarioName = scenario ? scenario.name : 'Scenario not found';
                                return (
                                    <div key={scenarioId} className="flex items-center mb-2">
                                        <input
                                            id={`checkbox-${scenarioId}`}
                                            type="checkbox"
                                            checked={selectedScenarios.includes(scenarioId)}
                                            onChange={() => handleCheckboxChange(scenarioId)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label htmlFor={`checkbox-${scenarioId}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {scenarioName}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        <button
                            onClick={handleSaveChanges}
                            className="mt-4 p-2 text-white bg-blue-500 rounded-md hover:bg-blue-700"
                        >
                            Save Changes
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ScenarioFilter;
