// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchDataObject, getUserData } from '../services/FirebaseService'
import { auth, usersCollection } from '../firebase';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const subscribe = auth.onAuthStateChanged(async authUser => {
            console.log(authUser ? "User logged in" : "User logged out");

            if (authUser) {
                const userData = await fetchDataObject(authUser.uid, usersCollection);
                setUser(userData);
            }
            else {
                setUser(null);
            }

        });
        return subscribe;
    }, []);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}