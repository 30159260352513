import React, { useEffect, useState } from 'react';
import { useUser } from '../../../contexts/UserContext';
import { useAppData } from '../../../contexts/AppDataContext';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../../../assets/images/22.png'; 
import { HiChevronRight } from 'react-icons/hi';
import { usePopUp } from '../../../contexts/PopUpContext';
import GradingDetails from '../../grades/GradingDetails';
import { GradingProvider } from '../../grades/contexts/GradingContext';

export default function LatestSubmissions({ limit, courseId }) {
    const currentUser = useUser();
    const { grades, getUserData, assignments, isLoading } = useAppData();
    const [filteredGrades, setFilteredGrades] = useState([]);
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();

    useEffect(() => {
        let allGrades = [];

        if (currentUser.roleName === 'Admin' || currentUser.roleName === 'Teacher') {
            allGrades = Object.values(grades).flat();
        } else if (currentUser.roleName === 'Student') {
            allGrades = grades[currentUser.id] || [];
        }

        // Filter by courseId if provided
        const filteredByCourse = courseId
            ? allGrades.filter((grade) => {
                const assignment = assignments.find((assignment) => assignment.id === grade.assessmentID);
                return assignment?.classroomId === courseId;
            })
            : allGrades;

        const sortedGrades = filteredByCourse
            .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
            .slice(0, limit);

        setFilteredGrades(sortedGrades);
    }, [grades, assignments, currentUser, courseId, limit]);

    const calculateCompletionCircle = (result) => {
        const roundedResult = Math.round(result); // Round the result to the nearest whole number
        const percentage = Math.min(Math.max(roundedResult, 0), 100);
        const circumference = 2 * Math.PI * 25; // Bigger circle with a radius of 25
        const dashArray = `${(percentage / 100) * circumference} ${circumference}`;
    
        return (
            <svg width="60" height="60"> {/* Larger SVG */}
                <circle
                    cx="30"  // Adjusted for larger SVG
                    cy="30"
                    r="25"
                    strokeWidth="5"
                    stroke="#e5e7eb"
                    fill="none"
                />
                <circle
                    cx="30"
                    cy="30"
                    r="25"
                    strokeWidth="5"
                    stroke={roundedResult >= 70 ? 'green' : roundedResult >= 50 ? 'blue' : 'red'}
                    fill="none"
                    strokeDasharray={dashArray}
                    transform="rotate(-90 30 30)" // Adjusted for the larger circle
                />
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize="14"
                    fontWeight="bold"
                    fill={roundedResult >= 70 ? 'green' : roundedResult >= 50 ? 'blue' : 'red'}
                >
                    {roundedResult}%
                </text>
            </svg>
        );
    };
    
    const handleGradeClick = (gradeId) => {
        showPopUp(
            <GradingProvider gradeId={gradeId}>
                <GradingDetails gradeId={gradeId} onClose={() => showPopUp(null)} />
            </GradingProvider>
        );
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-700">Latest Submissions</h3>
                <button
                    className="text-blue-500 hover:underline"
                    onClick={() => navigate('/main/gradesoverview')}
                >
                    See Grades →
                </button>
            </div>
            {isLoading ? (
                <SkeletonLoader type="card" />
            ) : filteredGrades.length > 0 ? (
                <ul>
                    {filteredGrades.map((grade) => {
                        const assignment = assignments.find((assignment) => assignment.id === grade.assessmentID);
                        const user = getUserData(grade.userID) || currentUser;

                        return (
                            <li
                                key={grade.id}
                                className="flex items-center justify-between py-2 border-b border-gray-200 cursor-pointer"
                                onClick={() => handleGradeClick(grade.id)}
                            >
                                <div className="flex items-center">
                                    <img
                                        src={user.photo_url || defaultImage}
                                        alt={user.firstName}
                                        className="h-10 w-10 rounded-full object-cover mr-3"
                                    />
                                    <div>
                                        <p className="text-gray-800 font-semibold">{`${user.firstName} ${user.lastName}`}</p>
                                        <p className="text-sm text-gray-600">{assignment?.assignmentName || 'Unknown Assignment'}</p>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-4">
                                    {calculateCompletionCircle(grade.averageResult)}
                                    <HiChevronRight className="text-gray-500" />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <p className="text-gray-500">No submissions found.</p>
            )}
        </div>
    );
}
