import React, { useEffect, useState } from "react";
import { useAppData } from "../../contexts/AppDataContext";
import { useUser } from "../../contexts/UserContext";
import { Dropdown, DropdownItem } from "flowbite-react";
import { useNavigate } from "react-router";
import { HiBookOpen } from "react-icons/hi";
import SkeletonLoader from "../../components/SkeletonLoader";
import { getFileUrl } from '../../services/FirebaseService';
import { usePopUp } from "../../contexts/PopUpContext";
import CreateAssignment from "../assesments/components/CreateAssignment";
import ProfilePicture from "../../components/ProfilePicture";
import Accordion from "../../components/Accordion";
import { HiX, HiOutlineDotsVertical } from 'react-icons/hi';
import PDFViewer from "../../components/PDFViewer"; 

const LibraryOverview = () => {
    const { templates, scenarios, isLoading } = useAppData();
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();
    const user = useUser();
    
    useEffect(() => {}, [templates]);
    useEffect(() => {}, [scenarios]);
    useEffect(() => {
        console.log(user);
    }, [user]);

    const handleSeeLibrary = () => {
        showPopUp(
            <FullLibraryViewPopUp onClose={() => showPopUp(null)} scenarios={scenarios} />
        );
    };

    const handleSeeTemplates = () => {
        showPopUp(
            <FullTemplateViewPopUp onClose={() => showPopUp(null)} templates={templates} />
        );
    };

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
                <div className="flex items-center space-x-2">
                    <HiBookOpen className="text-4xl text-gray-700 dark:text-gray-300" />
                    <h1 className="pl-5 text-2xl font-semibold text-gray-900 dark:text-white">Library</h1>
                </div>
            </div>

            {isLoading ? (
                <SkeletonLoader type='card' />
            ) : (
                <div>
                    {/* Library Accordion */}
                    <Accordion
                        title={`Made By Courseta (${scenarios.length})`}
                        isOpenByDefault={true}
                        onViewAll={handleSeeLibrary}
                        itemCount={scenarios.length}
                        content={(
                            <div className="p-5">
                                {scenarios.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                        {scenarios.slice(0, 8).map((scenario) => (
                                            <LibraryCard key={scenario.id} scenario={scenario} currentUser={user} />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center">
                                        <p className="text-gray-700 dark:text-white text-lg font-medium text-center">
                                            The Library is Empty, contact us to get more Scenarios.
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        showViewAll={true}
                    />

                    {/* Templates Accordion */}
                    <Accordion
                        title={`My Templates (${templates.length})`}
                        isOpenByDefault={true}
                        onViewAll={handleSeeTemplates}
                        itemCount={templates.length}
                        content={(
                            <div className="p-5">
                                {templates.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                        {templates.slice(0, 8).map(template => (
                                            <ActiveAssignmentCard
                                                key={template.id}
                                                template={template}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center">
                                        <p className="text-gray-700 dark:text-white text-lg font-medium text-center">
                                            Your library is currently empty.
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        showViewAll={true}
                    />
                </div>
            )}
        </div>
    );
};

const LibraryCard = ({ scenario, currentUser }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const { showPopUp } = usePopUp();
  
    useEffect(() => {
        const fetchImage = async () => {
            if (scenario && scenario.scenarioPicture) {
                const url = await getFileUrl(scenario.scenarioPicture);
                setImageUrl(url);
            }
        };
        fetchImage();
    }, [scenario.scenarioPicture]);

    const handleScenarioInfo = async () => {
        const scenarioInfoUrl = await getFileUrl(scenario.scenarioInfo); // Assuming scenarioInfo contains the PDF link
        showPopUp(
            <ScenarioInfo onClose={() => showPopUp(null)} scenarioInfoUrl={scenarioInfoUrl} scenarioName={scenario.name} />
        );
    };

    return (
        <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full">
            {/* Scenario Image */}
            {imageUrl ? (
                <div className="w-full h-full">
                    <img className="object-cover w-full h-full" src={imageUrl} alt={scenario?.name ?? "Scenario Image"} />
                </div>
            ) : (
                <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
                    <span>Loading Image...</span>
                </div>
            )}

            {/* Card content */}
            <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                    <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                        {scenario?.name ?? "Untitled Scenario"}
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 mb-4">{scenario?.description}</p>
                </div>

                {/* Show Info Button */}
                <button
                    onClick={handleScenarioInfo}
                    type="button"
                    className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium mt-4"
                >
                    Show Info
                </button>
            </div>
        </div>
    );
};

const ActiveAssignmentCard = ({ template }) => {
    const user = useUser();
    const navigate = useNavigate();
    const { showPopUp } = usePopUp();
    const { deleteTemplate } = useAppData();
    const [imageUrl, setImageUrl] = useState(null);

    const handleNavigateAfterCreate = (selectedScenario, classroomId, assignmentName) => {
        navigate(`/main/scenarioDetails/${selectedScenario}`, {
            state: { classroomId, assignmentName },
        });
    };

    const handleCreateAssignment = () => {
        showPopUp(
            <CreateAssignment
                classroomId={template.classroomId}
                onNavigateAfterCreate={handleNavigateAfterCreate}
                onClose={() => showPopUp(null)}
            />
        );
    };

    useEffect(() => {
        const fetchImage = async () => {
            if (template && template.scenarioPicture) {
                const url = await getFileUrl(template.scenarioPicture);
                setImageUrl(url);
            }
        };
        fetchImage();
    }, [template.scenarioPicture]);

    const date = template.creationDate.toDate();
    const formattedDateTime = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        + ' ' + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return (
        <div className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between h-full">
            {/* Template Image */}
            {imageUrl ? (
                <div className="w-full h-full">
                    <img className="object-cover w-full h-full" src={imageUrl} alt="Template Image" />
                </div>
            ) : (
                <div className="w-full h-56 bg-gray-300 flex items-center justify-center">
                    <span>Loading Image...</span>
                </div>
            )}

            {/* Card content */}
            <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                    <h4 className="text-md font-semibold text-gray-800 dark:text-white truncate">
                        {template.assignmentName ?? "Untitled Template"}
                    </h4>
                    <p className="text-gray-600 dark:text-gray-400 mb-4">{template.description}</p>
                    <p className="text-gray-500 dark:text-gray-300 text-sm">{formattedDateTime}</p>
                </div>

                {/* Action Buttons */}
                <div className="flex space-x-2 mt-4">
                    <button
                        onClick={handleCreateAssignment}
                        type="button"
                        className="w-full flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                    >
                        Create Assignment
                    </button>
                    {user.role === 'admin' && (
                        <Dropdown inline={true} label={<HiOutlineDotsVertical className="text-gray-700 dark:text-white" />}>
                            <DropdownItem onClick={() => deleteTemplate(template.id)}>
                                <span className="text-red-500">Delete</span>
                            </DropdownItem>
                        </Dropdown>
                    )}
                </div>
            </div>
        </div>
    );
};



const FullLibraryViewPopUp = ({ onClose, scenarios }) => {
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-8 z-50">
            <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-6">
                <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
                    <h2 className="text-xl font-semibold">Full Library View</h2>
                    <button onClick={onClose} className="text-white hover:text-gray-300">
                        <HiX className="h-6 w-6" />
                    </button>
                </div>
                <div className="p-6 bg-white max-h-[80vh] overflow-y-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {scenarios.map((scenario) => (
                            <LibraryCard key={scenario.id} scenario={scenario} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const FullTemplateViewPopUp = ({ onClose, templates }) => {
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-8 z-50">
            <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-6">
                <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
                    <h2 className="text-xl font-semibold">Full Template View</h2>
                    <button onClick={onClose} className="text-white hover:text-gray-300">
                        <HiX className="h-6 w-6" />
                    </button>
                </div>
                <div className="p-6 bg-white max-h-[80vh] overflow-y-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {templates.map((template) => (
                            <ActiveAssignmentCard key={template.id} template={template} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


// Scenario Info Component for viewing PDF and downloading it
const ScenarioInfo = ({ onClose, scenarioInfoUrl, scenarioName }) => {
    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = scenarioInfoUrl;
      link.setAttribute('target', '_blank'); // Opens the PDF in a new tab
      link.setAttribute('rel', 'noopener noreferrer'); // Adds security measures
      link.setAttribute('download', `${scenarioName}.pdf`); // Sets the default download name for the PDF
      link.click(); // Triggers the download
    };
  
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center p-8 z-50">
        <div className="relative bg-gray-800 rounded-lg shadow-lg w-full max-w-6xl p-6">
          <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
            <h2 className="text-xl font-semibold">Scenario Info {scenarioName}</h2>
            <div className="flex space-x-4">
              {/* Download Button */}
              <button
                onClick={handleDownload}
                className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg flex items-center"
              >
                See PDF
              </button>
              <button onClick={onClose} className="text-white hover:text-gray-300">
                <HiX className="h-6 w-6" />
              </button>
            </div>
          </div>
          <div className="p-6 bg-white max-h-[80vh] overflow-y-auto">
            <PDFViewer pdfUrl={scenarioInfoUrl} />
          </div>
        </div>
      </div>
    );
  };
  
  
export default LibraryOverview;
