import React from 'react';
import Accordion from '../../../components/Accordion';
import AssessmentDetails from '../../assesments/AssessmentDetails';
import GradesOverview from '../../overviews/GradesOverview';




const Grades = ({course}) => {

    return (
        <div>
        <GradesOverview courseId={course?.id}/>
            {/* <AssessmentDetails /> */}
            {/* <Accordion
                title="Assessment Details"
                content={<AssessmentDetails />}
                isOpenByDefault={true}
            /> */}
        </div>
    );
};

export default Grades;
