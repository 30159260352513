import React, { useState } from 'react';
import GradingDetails from '../grades/GradingDetails';
import Prebriefing from './sections/Prebriefing'
import Reflection from './sections/Reflection';
import Debriefing from './sections/Debriefing';
import { useAssessment } from './contexts/AssessmentContext';
import Start from './sections/Start';
import { useUser } from '../../contexts/UserContext';
import { useNotification } from '../../contexts/NotificationContext';

import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { GradingProvider } from '../grades/contexts/GradingContext';
import Performance from './sections/Performance';

export default function SimulationDetails() {
  const { assessment } = useAssessment();
  const user = useUser();
  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5; // Aantal stappen in de stepper

  const [grade, setGrade] = useState(null);

  const goToNextStep = () => {
    setCurrentStep(prev => (prev < totalSteps ? prev + 1 : prev));
    console.log(currentStep);
  };

  const goToPreviousStep = () => {
    setCurrentStep(prev => (prev > 1 ? prev - 1 : prev));
  };

  const getStepIcon = (step) => {
    return step < currentStep ? (
      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
      </svg>
    ) : (
      <span className="me-2">{step}</span>
    );
  };

  const getStepClass = (step) => {
    let classes = `flex md:w-full items-center ${step === currentStep ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500 dark:text-gray-400'} sm:after:content-[''] after:w-full after:h-1 ${step < currentStep ? 'after:border-b after:border-blue-600 dark:after:border-blue-500' : 'after:border-b after:border-gray-200 dark:after:border-gray-700'} after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10`;
    if (step === totalSteps) classes += ' sm:after:content-none'; // Verwijdert de streep na de laatste stap
    return classes;
  };

  const handleViewPerformance = (async () => {
    const userDocRef = doc(firestore, 'users', user.id);
    const userDoc = await getDoc(userDocRef);
    const gradesArray = userDoc.data()?.grades || [];
    // Pak het laatste element van de grades array
    if (gradesArray.length > 0) {
      const lastGradeId = gradesArray[gradesArray.length - 1];
      // Gebruik de laatste gradeId om het grade document op te halen
      const gradeRef = doc(firestore, 'grades', lastGradeId);
      const gradeDoc = await getDoc(gradeRef);

      if (gradeDoc.exists()) {
        const assessmentId = gradeDoc.data().assessmentID;
        console.log(assessmentId);
        if (assessmentId === assessment.id) {
          console.log("Last grade was correct assessment");
          setGrade(gradeDoc.data());
          goToNextStep();
        }
        else {
          console.log("Last grade was NOT correct assessment");
          showNotification('danger', "You have not completed the assignment yet");
        }
      } else {
        console.log('No such grade document!');
        showNotification('danger', "You have not completed the assignment yet");
      }
      goToNextStep();
    }
    else {
      showNotification('danger', "You have not completed the assignment yet");
    }
    //onContinue();
  });

  // Steps titles
  const stepsTitles = ['Prebriefing', 'Start', 'Grades', 'Debriefing', 'Reflection'];

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return <Prebriefing onContinue={goToNextStep} />;
      case 2:
        return <Start onReturn={goToPreviousStep} onContinue={handleViewPerformance} />;
      case 3:
        return <GradingProvider optionalGrade={grade}> <Performance onContinue={goToNextStep} /> </GradingProvider>;
      case 4:
        return <Debriefing onContinue={goToNextStep} />;
      case 5:
        return <GradingProvider optionalGrade={grade}><Reflection /></GradingProvider>;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900 py-4 lg:py-8 antialiased">
      {/* Terugkeerknop */}
      {/* <button
        onClick={() => history(-1)}
        className="mb-4 m-4 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        {/* SVG voor Terugkeerknop hier */}
      {/* Quit assessment
      </button> */}

      <h2 class="mb-4 text-6xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">{assessment ? assessment.assignmentName : "Loading..."}</h2>

      <div className="pt-10 max-w-none mx-auto px-8">
        {/* Stepper */}
        <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
          {stepsTitles.map((title, index) => (
            <li key={title} className={getStepClass(index + 1)}>
              <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                {getStepIcon(index + 1)}
                {title}
              </span>
            </li>
          ))}
        </ol>
      </div>
      <div className="step-content">
        {renderStepContent(currentStep)}
      </div>

      {/*
      <div className="pt-20 flex justify-center">
        {
        currentStep > 1 &&
          <button onClick={goToPreviousStep} className="flex items-center justify-center px-6 h-12 me-3 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          Return
        </button>
        }
        <button onClick={goToNextStep} className="flex items-center justify-center px-6 h-12 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          {currentStep === totalSteps ? 'Finish' : 'Continue'}
        </button>
      </div>*/}
    </section>
  );
}