import React from 'react';

const SideSteps = ({ steps, activeStep, setActiveStep }) => {
  return (
    <div className="flex flex-col space-y-6">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <button
            onClick={() => setActiveStep(index)}
            className={`flex items-center space-x-2 ${
              activeStep === index ? 'text-blue-600' : 'text-gray-500 dark:text-gray-400'
            }`}
          >
            <div
              className={`h-8 w-8 flex items-center justify-center rounded-full border ${
                activeStep === index ? 'bg-blue-600 text-white' : 'border-gray-300 dark:border-gray-600'
              }`}
            >
              {index + 1}
            </div>
            <span className="flex-1">{step}</span>
          </button>
          {index < steps.length - 1 && (
            <div className="flex items-center">
              <div className="h-12 border-l border-gray-300 dark:border-gray-600 ml-4"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SideSteps;
