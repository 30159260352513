import React, { useEffect, useState } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { usePopUp } from '../../contexts/PopUpContext';
import UserInfo from '../user_profile/UserInfo_Popup';
import AddPeopleToClassroom from '../creators/AddPeopleToClassroom';
import CreateAssignment from '../assesments/components/CreateAssignment';
import { useNavigate } from 'react-router-dom';
import { useCourse } from './contexts/CourseContext';
import { useUser } from '../../contexts/UserContext';
import { useAppData } from '../../contexts/AppDataContext';
import UploadDocument from './components/UploadDocument';
import { HiHome } from "react-icons/hi";
import TabNavigation from '../../components/TabNavigation';
import Overview from './tabs/Overview';
import Modules from './tabs/Modules';
import VRLibrary from './tabs/VRLibrary';
import Materials from './tabs/Materials';
import Assignments from './tabs/Assignment';
import People from './tabs/People';
import Grades from './tabs/Grades';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';



export default function ClassRoomDetails() {
    const { showNotification } = useNotification();
    const { showPopUp } = usePopUp();
    const navigate = useNavigate();
    const [attendees, setAttendees] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [published, setPublished] = useState(false);

    const user = useUser();
    const { course, uploadCourseFiles, deleteCourseFile, addUsers, removeUsers, addScenarios, removeScenarios } = useCourse();
    const { users, getUserListData, getUserData } = useAppData();

    useEffect(() => {
        if (course) {
            setAttendees(getUserListData(course?.attendees));
            setPublished(course.published || false); // Set the initial published state
            console.log("Dit is de course" + course.id);
        }
    }, [course, users]);

    useEffect(() => {
        if (user) {
            console.log("De admin" + user.email);
        }
    }, [user]);

    const handleNavigateAfterCreate = (selectedScenario, classroomId, assignmentName) => {
        navigate(`/main/scenarioDetails/${selectedScenario}`, {
            state: { classroomId: classroomId, assignmentName: assignmentName },
        });
    };

    const handleUploadFiles = async (files) => {
        await uploadCourseFiles(files);
        showNotification('success', `Files uploaded`);
    };

    const handleDeleteMaterial = async (fileName) => {
        deleteCourseFile(fileName);
        showNotification('success', 'Material deleted');
    };

    const handleAttendeeClick = (userId) => {
        const userToShow = getUserData(userId);
        showPopUp(<UserInfo user={userToShow} onClose={() => showPopUp(null)} userRole={user?.roleName} />);
    };

    const handleAddPeopleToClassroom = () => {
        showPopUp(
            <AddPeopleToClassroom
                course={course}
                addUsers={addUsers}
                removeUsers={removeUsers}
                onClose={() => showPopUp(null)}
            />
        );
    };

    const handleCreateAssignment = () => {
        const schoolID = course?.schoolID;
        showPopUp(
            <CreateAssignment
                classroomId={course?.id}
                schoolID={schoolID}
                onNavigateAfterCreate={handleNavigateAfterCreate}
                onClose={() => showPopUp(null)}
            />
        );
    };

    const handleUploadDocument = () => {
        showPopUp(
            <UploadDocument
                handleUploadFiles={handleUploadFiles}
            />
        );
    };




    const handleTogglePublish = async () => {
        if (!course?.id) return;

        const courseRef = doc(firestore, 'classRooms', course.id);

        try {
            const courseDoc = await getDoc(courseRef);

            if (courseDoc.exists()) {
                const newPublishedStatus = !published;
                await updateDoc(courseRef, { published: newPublishedStatus });
                setPublished(newPublishedStatus);
                showNotification('success', `Course ${newPublishedStatus ? 'published' : 'unpublished'} successfully!`);
            } else {
                await setDoc(courseRef, { published: true }, { merge: true });
                setPublished(true);
                showNotification('success', 'Course published successfully!');
            }
        } catch (error) {
            console.error("Error updating document: ", error);
            showNotification('danger', 'Failed to update course.');
        }
    };

    const tabs = ['Overview', 'Modules', 'VR Library', 'Materials', 'Assignments', 'People', 'Grades'];
    const components = [
        <Overview
            handleCreateAssignment={handleCreateAssignment}
            addScenarios={addScenarios}
            removeScenarios={removeScenarios}
            course={course}
        />,
        <Modules 
                 handleCreateAssignment={handleCreateAssignment} 
                 handleUploadDocument={handleUploadDocument} />,
        <VRLibrary />,
        <Materials
            handleUploadDocument={handleUploadDocument}
            course={course}
            user={user}
            showNotification={showNotification}
            handleDeleteMaterial={handleDeleteMaterial}
        />,
        <Assignments handleCreateAssignment={handleCreateAssignment} />,
        <People
            attendees={attendees}
            user={user}
            handleAttendeeClick={handleAttendeeClick}
            handleAddPeopleToClassroom={handleAddPeopleToClassroom}
            course={course}
        />,
        <Grades course={course?.id} />
    ];

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-4 border-b pb-4 border-gray-200 dark:border-gray-700">
              <div className="flex items-center space-x-2">
                <HiHome className="text-4xl text-gray-700 dark:text-gray-300" />
                <div>
               
            </div>
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {course?.className ?? 'Course'}
                </h1>
              </div>
              {(user?.roleName === 'Admin' || user?.roleName === 'Teacher') && (
                <button
                  onClick={handleTogglePublish}
                  type="button"
                  className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                >
                  {published ? 'Unpublish This Course' : 'Publish This Course'}
                </button>
              )}
            </div>
      
            {/* Centering the TabNavigation */}
            <div className="flex items-center justify-center w-full">
              <TabNavigation
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                components={components}
              />
            </div>
          </div>
        </div>
      );
      

}
