import './App.css';
import { NotificationProvider } from './contexts/NotificationContext';
import Notification from './components/Notification';
import AppRouter from './routing/AppRouter';
import { UserProvider } from './contexts/UserContext'; // Import the UserProvider
import { PopUpProvider } from './contexts/PopUpContext';
import PopUp from './components/PopUp';
import { SettingsProvider } from './contexts/SettingsContext';
import { AppDataProvider } from './contexts/AppDataContext';
import { OverviewProvider } from './contexts/OverviewContext';

function App() {
  return (
    <NotificationProvider>
      <SettingsProvider>
        <UserProvider>
          <AppDataProvider>
            <OverviewProvider>
              <Notification />
              <PopUpProvider>
                <AppRouter />
                <PopUp />
              </PopUpProvider>
            </OverviewProvider>
          </AppDataProvider>
        </UserProvider>
      </SettingsProvider>
    </NotificationProvider>
  );
}

export default App;
