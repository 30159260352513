import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database'; // Gebruik getDatabase in plaats van getFirestore voor Realtime Database
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

export const usersCollection = 'users';
export const pendingUsersCollection = 'pendingRegistrations';
export const coursesCollection = 'classRooms'
export const assignmentCollection = 'assessments'
export const scenariosCollection = 'scenarios'
export const schoolsCollection = 'schools'
export const gradesCollection = 'grades'
export const templatesCollection = 'templates'
export const questionnairesCollection = 'questionaires'
export const modulesCollection = 'modules'
export const testscriptingCollection = 'testscripting'
export const scriptDataCollection = 'scriptData'
export const medicationsCollection = 'medications'
export const conditionsCollection = 'conditions'
export const gradesScriptingCollection = 'gradesScripting'


const firebaseConfig = {
    apiKey: "AIzaSyBVclQhxRiKdjTlRRsuqCvu4Ji_lwHm56s",
    authDomain: "virtualedu-54837.firebaseapp.com",
    databaseURL: "https://virtualedu-54837-default-rtdb.firebaseio.com",
    projectId: "virtualedu-54837",
    storageBucket: "virtualedu-54837.appspot.com",
    messagingSenderId: "3368073778",
    appId: "1:3368073778:web:b767758719c67298e59a2b",
    measurementId: "G-DQ0CB6F6HR"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
//const database = getDatabase(app);
const functions = getFunctions(app);




export { 
    auth, 
    signInWithEmailAndPassword, 
    onAuthStateChanged, firestore, 
    //database, Deze heb ik niet nodig als het goed is
    httpsCallable,
    app,
    functions, 
    storage, 
    sendPasswordResetEmail, 
    createUserWithEmailAndPassword

};